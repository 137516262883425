import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { useAppSelector } from 'store/hooks';
import { Modal, Input, RemoveModal } from '@forma/forma-ui-kit';
import ContextMenu from 'components/ContextMenu';
import { IFolderListChidlren, IFolderListTreeChidlren, IFoldersParent } from 'interfaces/folders.interface';
import { IUserGroupsItem } from 'interfaces/users.interface';
import { IFoldersActionsProps, IFoldersEditingProps } from 'interfaces/fileExplorer.interface';
import { selectUserPermissions } from 'store/user/userSlice';

import styles from './explorer-context-menu.module.css';

interface ExplorerContextMenuProps {
  open: boolean,
  item: IFolderListChidlren,
  items?: IFolderListChidlren[]|IFolderListTreeChidlren[],
  parent?: IFoldersParent,
  onToggleMenu: (id: string|null) => void,
  onToggleModal: (open: boolean) => void,
  onHoverSubmenuItem: (id: string|null) => void,
  userGroups?: IUserGroupsItem[],
  editing?: IFoldersEditingProps,
  actions?: IFoldersActionsProps,
  position: PopupPosition[]|PopupPosition
}

const ExplorerContextMenu: FC<ExplorerContextMenuProps> = ({
  open, item, items, parent, onToggleMenu, onToggleModal, onHoverSubmenuItem, userGroups, editing = {}, actions = {}, position
}) => {
  const { t } = useTranslation();
  const userPermissions = useAppSelector(selectUserPermissions);
  const canChangeFolders = userPermissions?.includes('folders_templates');
  const canDeleteFolders = userPermissions?.includes('folders_templates_delete');
  const [ isShowDelete, setShowDelete ] = useState(false);
  const [ isShowRename, setShowRename ] = useState(false);
  const [ newName, setName ] = useState(item.name);
  const isFolder = item.type === 'folder';

  const { onRemove, onRename, onChangeAccess, onCopy, onMove, onDuplicate } = editing;
  const { onFavouritesToggle, onCopyLink, onCopyShared } = actions;

  const handleClickRemove = () => {
    if (onRemove) onRemove({ id: item.id, translatedName: item.translatedName, type: item.type });
    setShowDelete(false);
    onToggleModal(false);
    document.body.style.overflow = 'auto';
  };

  const handleClickRename = () => {
    if (onRename) {
      onRename({
        id: item.id,
        type: item.type,
        name: newName,
        parentid: parent?.id,
        groups: item.groups
      });
    }
    setShowRename(false);
    onToggleModal(false);
  };

  const handleChangeGroups = (groups: string[]) => {
    if (onChangeAccess)
      onChangeAccess({
        id: item.id,
        type: item.type,
        name: item.name,
        parentid: parent?.id,
        groups: groups
      });
    setShowRename(false);
    onToggleModal(false);
  };

  const handleClickGroup = (groupId: string, checked: boolean) => {
    if (checked) handleChangeGroups([ ...item.groups, groupId ]);
    else handleChangeGroups(item.groups.filter((group: string) => groupId !== group));
  };

  const options = [];

  if (item.external) {
    if (!isFolder && onCopyShared) {
      options.push({
        onClick: () => onCopyShared({ id: item.id, type: item.type }),
        text: t('copy_to_yourself')
      });
    }
  } else {
    if (!isFolder && canChangeFolders) {
      options.push({
        link: `/editor/${item.translatedName}`,
        text: t('edit')
      });
    }

    if (onFavouritesToggle) {
      options.push({
        onClick: () => onFavouritesToggle({ id: item.id, type: item.type, checked: !item.favorite }),
        text: item.favorite ? t('remove_from_favourites') : t('to_favourites')
      });
    }

    if (canChangeFolders) {
      options.push({
        onClick: () => {
          setShowRename(true);
          onToggleModal(true);
        },
        text: t('rename')
      });

      if (items?.length && onCopy) {
        const folders = items.filter(({ type }) => type === 'folder');
        if (folders.length) {
          options.push({
            text: t('copy'),
            items: folders.reduce((acc: any[], { id: targetId, name }) => {
              if (targetId === 'main' || targetId === 'packs') return acc;
              return [ ...acc, {
                text: name,
                onClick: () => {
                  onCopy({ id: item.id, targetId, name: item.name, type: item.type });
                  onHoverSubmenuItem(null);
                },
                onMouseEnter: () => onHoverSubmenuItem(targetId),
                onMouseLeave: () => onHoverSubmenuItem(null),
                disabled: targetId === parent?.id
              }];
            }, [])
          });
        }
      }

      if (items?.length && onMove) {
        const folders = items.filter(({ type }) => type === 'folder');
        if (folders.length) {
          options.push({
            text: t('move'),
            items: folders.reduce((acc: any[], { id: targetId, name }) => {
              if (targetId === 'main' || targetId === 'packs') return acc;
              return [ ...acc, {
                text: name,
                onClick: () => {
                  onMove({ id: item.id, targetId, name: item.name, type: item.type });
                  onHoverSubmenuItem(null);
                },
                onMouseEnter: () => onHoverSubmenuItem(targetId),
                onMouseLeave: () => onHoverSubmenuItem(null),
                disabled: targetId === parent?.id
              }];
            }, [])
          });
        }
      }

      if (parent?.id) {
        if (onMove) {
          options.push({
            text: t('move_to_level_up'),
            onClick: () => {
              onMove({ id: item.id, targetId: parent.parentId ?? null, name: item.name, type: item.type });
              onHoverSubmenuItem(null);
            },
            onMouseEnter: () => onHoverSubmenuItem('back'),
            onMouseLeave: () => onHoverSubmenuItem(null),
          });
        }
        if (onCopy) {
          options.push({
            text: t('copy_to_level_up'),
            onClick: () => {
              onCopy({ id: item.id, targetId: parent.parentId ?? null, name: item.name, type: item.type });
              onHoverSubmenuItem(null);
            },
            onMouseEnter: () => onHoverSubmenuItem('back'),
            onMouseLeave: () => onHoverSubmenuItem(null),
          });
        }
      }

      if (onDuplicate) {
        options.push({
          text: t('duplicate'),
          onClick: () => {
            onDuplicate({ id: item.id, targetId: parent?.id ?? null, name: item.name, type: item.type });
            onHoverSubmenuItem(null);
          }
        });
      }
    }

    if (canDeleteFolders) {
      options.push({
        onClick: () => {
          setShowDelete(true);
          onToggleModal(true);
        },
        text: isFolder ? t('remove_folder') : t('remove_template')
      });
    }

    if (isFolder && canChangeFolders && userGroups && onChangeAccess) {
      options.push({
        onClick: () => {
          setShowRename(true);
          onToggleModal(true);
        },
        text: t('access_rights'),
        items: userGroups?.map(({ id: groupId, name }) => {
          const isActive = item.groups && item.groups.find((group: string) => group === groupId);
          return ({
            text: name,
            onClick: () => handleClickGroup(groupId, !isActive),
            icon: isActive && <ReactSVG className={styles.iconChecked} src="/icons/checked.svg" wrapper="span" />
          });
        })
      });
    }

    if (onCopyLink) {
      options.push({
        onClick: () => onCopyLink({ translatedName: item.translatedName, parentSlug: parent?.translatedName, type: item.type }),
        text: isFolder ? t('copy_link_to_folder') : t('copy_link_to_file')
      });
    }
  }

  return (
    <>
      <ContextMenu
        open={open}
        items={options}
        position={position}
        control={open => (
          <button className={classNames(styles.button, open && styles.active)}>
            <ReactSVG src="/icons/more.svg" />
          </button>
        )}
        offsetY={12}
        offsetX={0}
        onOpen={() => onToggleMenu(item.id)}
        onClose={() => onToggleMenu(null)}
      />

      <Modal
        open={isShowRename}
        onClose={() => {
          setShowRename(false);
          onToggleModal(false);
        }}
        title={isFolder ? t('rename_folder') : t('rename_template')}
        size="small"
        closeOnDocumentClick={false}
        // on={[ 'hover', 'focus' ]}
        buttons={[
          {
            children: t('save'),
            onClick: () => handleClickRename()
          },
          {
            children: t('cancel'),
            viewStyle: 'tertiary'
          }
        ]}
      >
        <div className={styles.renameModal}>
          <Input
            id="edit_folder_name"
            name="name"
            placeholder={isFolder ? t('enter_folder_name') : t('enter_template_name')}
            onChange={e => setName(e.target.value)}
            maxLength={100}
            value={newName}
            meta={{
              showClearButton: true
            }}
          />
        </div>
      </Modal>

      <RemoveModal
        open={isShowDelete}
        onClose={() => {
          setShowDelete(false);
          onToggleModal(false);
        }}
        onRemove={handleClickRemove}
        itemName={item.name}
        title={isFolder ? t('deleting_folder') : t('deleting_template')}
      />
    </>
  );
};

export default ExplorerContextMenu;
