
import { useTranslation } from 'react-i18next';
import useTimer from 'hooks/useTimer';
import { useNavigate } from 'react-router-dom';
import { DISCOUNT_DURATION, DISCOUNT_PROMOCODE, DISCOUNT_TARIFF } from 'data/constants';
import { setCookie } from 'helpers/cookies';
import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/userSlice';
import { analytics } from 'helpers/analytics';

import styles from './discount-label.module.css';

const DiscountLabel = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUserData);
  const navigate = useNavigate();

  const now = new Date();
  const endDate = user && new Date(user.workspace.createdAt);
  if (endDate) endDate.setDate(endDate.getDate() + 1);
  const diff = (endDate && endDate > now) ? Math.floor((endDate.getTime() - now.getTime())/1000) : 0;

  const { left } = useTimer(diff);

  if (!diff || !left) return null;

  const hours = `0${Math.floor(left / 3600)}`.slice(-2);
  const _left = left % 3600;
  const minutes = `0${Math.floor(_left / 60)}`.slice(-2);
  const seconds = `0${_left % 60}`.slice(-2);

  const handleClick = () => {
    const date = new Date();
    localStorage.setItem('promocode', DISCOUNT_PROMOCODE);
    analytics.useDiscount();
    setCookie(
      'tariff',
      JSON.stringify({ tariff: DISCOUNT_TARIFF, duration: DISCOUNT_DURATION }),
      {
        path: '/',
        expires: new Date(date.setDate(date.getDate() + 7)),
        domain: window.location.hostname
      }
    );
    navigate('/select-tariff');
  };

  return (
    <div className={styles.root} onClick={handleClick}>
      <img className={styles.icon} src="/images/gift.svg" width={44} height={40} alt="" />
      <div className={styles.content}>
        <div className={styles.title}>{t('guide.bonus_description')}</div>
        <div className={styles.timer}>
          {hours}:{minutes}:{seconds}
        </div>
      </div>
    </div>
  );
};

export default DiscountLabel;
