import { useContext, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton, Input, ButtonsContainer, ThemeContext } from '@forma/forma-ui-kit';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import RegisterBanner from 'views/account/RegisterBanner';
import getValidationSchema from 'data/validationSchema';
import { getCookie, setCookie } from 'helpers/cookies';

import { useGetMailDomainQuery, useVerifyUserMutation } from 'store/auth/authApi';

import styles from './Account.module.css';

interface FormValues {
  login: string
}

const landingUrl = process.env.REACT_APP_LANDING_URL;

const Register = () => {
  const { t } = useTranslation();
  const [ searchParams ] = useSearchParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { lang } = useContext(ThemeContext);
  const [ sendCode, { isLoading, isError } ] = useVerifyUserMutation();

  const { register, handleSubmit, watch, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'login' ])), defaultValues: { login: state?.login }
  });
  const login = watch('login');

  const { data: mailDomains } = useGetMailDomainQuery({ email: login }, { skip: !isValid });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    sendCode(data).then(result => {
      if ('data' in result) navigate('/register/code', { state: { login: data.login } });
    });
  };

  useEffect(() => {
    const date = new Date();
    const cookieUtm = getCookie('utm');
    const utm: { [key: string]: string } = cookieUtm ? JSON.parse(decodeURIComponent(cookieUtm)) : {};
    for (const [key, value] of searchParams.entries()) {
      utm[key] = value;
    }
    setCookie('utm', JSON.stringify(utm), {
      path: '/',
      expires: new Date(date.setDate(date.getDate() + 7)),
      domain: window.location.hostname
    });
  }, [searchParams]);

  return (
    <MainLayout
      title={<Trans i18nKey="account.create_account_for_documents" components={{ accent: <span className="accent-text" /> }}/>}
      subtitle={t('account.trial_notice')}
      sideChildren={<RegisterBanner />}
    >
      <PageTitle>{t('site_name') + ' – ' + t('register2')}</PageTitle>

      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="login"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="login"
                placeholder={t('placeholders.login')}
                maxLength={255}
                inputMode="email"
                {...rest}
                {...register}
                value={value}
                autoComplete="email"
                data-testid="login"
                meta={{
                  label: t('account.input_your_working_email'),
                  error: !!errors?.login?.message || isError,
                  errorMessage: !!errors?.login?.message && t('errors.' + errors.login.message),
                  showClearButton: true
                }}
              />
            )}
          />
        </div>
        {!!(isValid && mailDomains?.length && mailDomains[0].name) && (
          <div className={styles.notice}>
            {t('account.use_company_email')}
          </div>
        )}
        <ButtonsContainer>
          <LoadingButton type="submit" disabled={!isValid} isLoading={isLoading} fullWidth shadow data-testid="submit">
            {t('continue')}
          </LoadingButton>
        </ButtonsContainer>
        <div className={styles.agreement}>
          <Trans
            i18nKey="account.register_oferta"
            values={{
              button: t('continue')
            }}
            components={{
              // eslint-disable-next-line
              agreement_tag: <a href={`${landingUrl}/${lang}/license/`} target="_blank" />,
              // eslint-disable-next-line
              privacy_tag: <a href={`${landingUrl}/${lang}/privacy/`} target="_blank" />
            }}
          />
        </div>
      </form>

      <div className={styles.anotherAction}>
        {t('account.already_have_account')} <Link to="/login" data-testid="goToLogin">{t('sign_in')}</Link>
      </div>
    </MainLayout>
  );
};

export default Register;
