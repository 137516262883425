import { useEffect, useReducer, useRef } from 'react';


const reducer = (state: { left: number }, action: { type: 'INCREASE'|'DECREASE'|'RESET', value: number }) => {
  switch (action.type) {
  case 'INCREASE': return { left: state.left + action.value };
  case 'DECREASE': return { left: state.left - action.value };
  case 'RESET': return { left: action.value };
  }
};

const useTimer = (seconds: number, onFinish?: () => void) => {
  const timeoutRef = useRef<NodeJS.Timeout|null>(null);
  const [ state, dispatch ] = useReducer(reducer, { left: seconds });

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [seconds]);

  useEffect(() => {
    timeoutRef.current = state.left > 0 ? setTimeout(() => dispatch({ type: 'DECREASE', value: 1 }), 1000) : null;
    if (state.left === 0 && onFinish) onFinish();

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [state.left, onFinish]);

  const reset = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    dispatch({ type: 'RESET', value: seconds });
  };

  return { ...state, reset };
};

export default useTimer;
