import { ComponentProps, FC, Fragment, lazy, Suspense, useContext, useState } from 'react';
import update from 'immutability-helper';
import {
  FillProducts,
  calculateTotalValues,
  mapTotalForCalc,
  calculateProductValues,
  nanoid,
  ThemeContext,
  recalculateNumbers,
  IProductsItem,
  mapProductToData,
} from '@forma/forma-ui-kit';

const ProductsImportModal = lazy(() => import('pages/Products/ProductsImportModal'));
const SelectProductsModal = lazy(() => import('pages/Products/SelectProductsModal'));

interface FillSideTableProps extends ComponentProps<typeof FillProducts> {}

const FillSideTable: FC<FillSideTableProps> = ({ data, products, ...props }) => {
  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];

  const [ showingModal, setShowingModal ] = useState<{ type: 'import'|'add', index: number }|null>();

  const handleSelectProducts = (items: IProductsItem[]) => {
    const newProducts: { [key: string]: string }[] = items.map(product => ({
      id: product.id,
      ...calculateProductValues({
        ...mapProductToData(product, data.columns.map(({ id }) => id)),
        category: product.category.name,
        number: '1',
        count: '1'
      }, currency.code)
    }));

    const next = recalculateNumbers(
      update(products, { $splice: newProducts.map((product, index) => ([ showingModal!.index+index+1, 0, product ])) })
    );
    const totalValues = calculateTotalValues(next, mapTotalForCalc(data.total, data.showTotalRow), currency.code);

    setShowingModal(prev => ({ type: 'add', index: prev!.index + items.length }));
    props.onChangeTable(next, totalValues, props.customProductsIds);
  };

  const handleDeselectProducts = (ids: string[]) => {
    const next = recalculateNumbers(products.filter(product => !ids.includes(product.id)));
    const totalValues = calculateTotalValues(next, mapTotalForCalc(data.total, data.showTotalRow), currency.code);
    props.onChangeTable(next, totalValues, props.customProductsIds);
  };

  return (
    <Fragment>
      <FillProducts
        data={data}
        products={products}
        {...props}
        onClickImport={(index) => setShowingModal({ type: 'import', index })}
        onClickAdd={(index) => setShowingModal({ type: 'add', index })}
      />

      <Suspense>
        <SelectProductsModal
          open={showingModal?.type === 'add'}
          onClose={() => setShowingModal(null)}
          title={data.name}
          // columnsIds={data.columns.map(({ id }) => id)}
          selectedIds={products.filter(({ id }) => !!id).map(({ id }) => id)}
          onSelect={handleSelectProducts}
          onDeselect={handleDeselectProducts}
        />
      </Suspense>
      <Suspense>
        <ProductsImportModal
          open={showingModal?.type === 'import'}
          onClose={() => setShowingModal(null)}
          onAddProducts={async (items) => {
            const customProductsIds = props.customProductsIds ?? [];
            const addedProducts = items.map(product => {
              const id = nanoid(12);
              const cols = data.columns.reduce((acc: { [key: string]: string }, current ) => (
                { ...acc, [current.id]: product[current.id] ?? '' }
              ), {});
              return calculateProductValues({ ...cols, id, number: '1', count: '1' }, currency.code);
            });

            const next = recalculateNumbers(update(products, { $splice: [[ showingModal!.index+1, 0, ...addedProducts ]] }));
            const totalValues = calculateTotalValues(next, mapTotalForCalc(data.total, data.showTotalRow), currency.code);
            props.onChangeTable(next, totalValues, [ ...customProductsIds, ...addedProducts.map(({ id }) => id) ]);

            return true;
          }}
        />
      </Suspense>
    </Fragment>
  );
};

export default FillSideTable;
