import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';
import { currencies } from 'data/mock';

import { IPromocode } from 'interfaces/tariffs.interface';

import styles from './promo-modal.module.css';

interface PromoModalProps {
  info?: IPromocode,
  onSubmit: (code: string) => Promise<boolean>,
  onCancel: () => void
}

const PromoModal = ({ info, onSubmit }: PromoModalProps) => {
  const { t } = useTranslation();
  const [ value, setValue ] = useState('');
  const [ isOpen, setOpen ] = useState(false);
  const [ isError, setError ] = useState(false);

  const handleClickSubmit = async () => {
    const res = await onSubmit(value);
    if (res) handleClose();
    else setError(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue('');
    setError(false);
  };

  const haveUnusedPromo = (info?.id && !info.used);
  const currency = currencies.ru;

  return (
    <div className={styles.root}>
      {haveUnusedPromo ? (
        <div className={styles.applied}>
          {t('subscription.applied_promocode')}: {info.discountPercent && `${info.discountPercent}%`} {info.discountAmount && formatPrice(info.discountAmount, currency)}
        </div>
      ) : (
        <Button viewStyle="text" onClick={() => setOpen(true)}>
          {t('subscription.i_have_promocode')}
        </Button>
      )}
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.title}>{t('promocode')}</div>
        <div className={styles.content}>
          <Input
            viewStyle="secondary"
            className={styles.input}
            name="promocode"
            placeholder={t('promocode')}
            onChange={e => setValue(e.target.value)}
            spellCheck={false}
            value={value}
            data-testid="promocode"
            meta={{
              label: t('subscription.enter_promocode'),
              labelClass: styles.label,
              error: isError,
              errorMessage: t('subscription.promocode_not_valid')
            }}
          />
          <Button onClick={handleClickSubmit}>{t('uikit:apply')}</Button>
        </div>
      </Modal>
    </div>
  );
};

export default PromoModal;
