import { FC, ReactNode, useState } from 'react';
import StaticTemplatesGrid from '../StaticTemplatesGrid';
import StaticTemplatesList from '../StaticTemplatesList';

import { ISharedFoldersTreeItem, ISharedTemplatesItem } from 'interfaces/sharedTemplates.interface';

import styles from './static-templates-folder.module.css';

interface StaticTemplatesFolderProps {
  level: number,
  searchValue: string,
  layout: 'grid'|'list',
  isLoading?: boolean,
  name?: string,
  folders?: ISharedFoldersTreeItem[],
  templates?: ISharedTemplatesItem[],
  onClickAddTemplate: (id: string) => Promise<string|null>,
}

const Title = ({ level, children, className }: { level: number, className: string, children: ReactNode }) => {
  if (level === 1) return <h2 className={className}>{children}</h2>;
  if (level === 2) return <h3 className={className}>{children}</h3>;
  if (level === 3) return <h4 className={className}>{children}</h4>;
  if (level === 4) return <h5 className={className}>{children}</h5>;
  return <h6 className={className}>{children}</h6>;
};

const StaticTemplatesFolder: FC<StaticTemplatesFolderProps> = ({
  level, searchValue, layout, isLoading, name, folders, templates, onClickAddTemplate
}) => {
  const [ addedTemplates, setAddedTemplates ] = useState<{ id: string, translatedName: string }[]>([]);

  const handleAddTemplate = async (id: string) => {
    const res = await onClickAddTemplate(id);
    if (res) setAddedTemplates(prev => [ ...prev, { id, translatedName: res }]);
  };

  const handleUseTemplate = async (id: string) => {
    const res = await onClickAddTemplate(id);
    if (res) {
      setAddedTemplates(prev => [ ...prev, { id, translatedName: res }]);
      // navigate(`/fill-document/${result}`); - doesn't work
      window.location.href = `/fill-document/${res}`;
    }
  };

  return (
    <div className={styles.root}>
      {name && (
        <Title className={styles.title} level={level}>{name}</Title>
      )}

      {layout === 'list' ? (
        <StaticTemplatesList
          isLoading={isLoading}
          searchValue={searchValue}
          items={templates}
          onClickAdd={handleAddTemplate}
          onClickUse={handleUseTemplate}
          addedTemplates={addedTemplates}
        />
      ) : (
        <StaticTemplatesGrid
          isLoading={isLoading}
          searchValue={searchValue}
          items={templates}
          onClickAdd={handleAddTemplate}
          onClickUse={handleUseTemplate}
          addedTemplates={addedTemplates}
        />
      )}

      {folders?.map(({ id, name, children, templates }) => (
        <StaticTemplatesFolder
          level={level+1}
          searchValue={searchValue}
          layout={layout}
          name={name}
          folders={children}
          templates={templates}
          onClickAddTemplate={onClickAddTemplate}
          key={id}
        />
      ))}
    </div>
  );
};

export default StaticTemplatesFolder;
