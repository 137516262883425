import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonsContainer, Modal, LoadingButton, Input } from '@forma/forma-ui-kit';

import { addNotification } from 'store/notifications/notificationsSlice';
import { useSetProfilePasswordMutation } from 'store/user/userApi';

import getValidationSchema from 'data/validationSchema';

import styles from './Profile.module.css';

interface FormValues {
  oldPassword: string,
  password: string,
  confirm_password: string
}

interface ChangePasswordModalProps {
  open: boolean,
  onClose: () => void
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ isShowPass, setShowPass ] = useState<boolean>(false);

  const [ setPassword, { isLoading } ] = useSetProfilePasswordMutation();

  const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'oldPassword', 'password', 'confirm_password' ]))
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const _data = {
      oldPassword: data.oldPassword,
      newPassword: data.password
    };

    const result = await setPassword(_data);
    if ('data' in result && result.data) {
      onClose();
      dispatch(addNotification({ content: t('password_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  const handleClickPassword = (id: string) => {
    setShowPass(!isShowPass);
    setTimeout(() => {
      const input = document.getElementById(id) as HTMLInputElement;
      input?.focus();
      input?.setSelectionRange(input.value.length, input.value.length);
    }, 250);
  };

  return (
    <Modal
      size="small"
      open={open}
      title={t('change_password')}
      onClose={onClose}
    >
      <form className={styles.passwordForm} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="oldPassword"
          render={({ field: { value = '', ...rest } }) => (
            <Input
              id="oldPassword"
              type={isShowPass ? 'text' : 'password'}
              placeholder={t('not_input')}
              className={styles.input}
              autoComplete="current-password"
              viewStyle="secondary"
              {...rest}
              {...register}
              value={value}
              meta={{
                label: t('account.input_old_password'),
                labelClass: styles.label,
                buttons: (
                  <img src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'} alt="" onClick={() => handleClickPassword('oldPassword')} />
                ),
                error: !!errors?.oldPassword?.message
                // errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { value = '', ...rest } }) => (
            <Input
              id="password"
              type={isShowPass ? 'text' : 'password'}
              placeholder={t('not_input')}
              className={styles.input}
              autoComplete="new-password"
              viewStyle="secondary"
              {...rest}
              {...register}
              value={value}
              meta={{
                containerClass: styles.inputContainer,
                label: t('account.input_new_password'),
                labelClass: styles.label,
                buttons: (
                  <img src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'} alt="" onClick={() => handleClickPassword('password')} />
                ),
                error: !!errors?.password?.message
              // errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="confirm_password"
          render={({ field: { value = '', ...rest } }) => (
            <Input
              id="confirm_password"
              type={isShowPass ? 'text' : 'password'}
              placeholder={t('not_input')}
              className={styles.input}
              autoComplete="off"
              viewStyle="secondary"
              {...rest}
              {...register}
              value={value}
              meta={{
                containerClass: styles.inputContainer,
                label: t('account.repeat_new_password'),
                labelClass: styles.label,
                buttons: (
                  <img src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'} alt="" onClick={() => handleClickPassword('confirm_password')} />
                ),
                error: !!errors?.confirm_password?.message
                // errorMessage={errors?.login?.message && t('errors.' + errors.login.message)}
              }}
            />
          )}
        />

        <ButtonsContainer>
          <LoadingButton
            type="submit"
            viewStyle="primary"
            disabled={!isValid}
            fullWidth={true}
            isLoading={isLoading}
          >
            {t('save_password')}
          </LoadingButton>
        </ButtonsContainer>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
