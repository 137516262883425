import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FallbackProps } from 'react-error-boundary';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import { Button } from '@forma/forma-ui-kit';

import styles from './ErrorPage.module.css';

const ErrorPage = ({ error }: FallbackProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (error.message?.includes('chunk')) {
      window.location.reload();
    }
  }, [error]);

  return (
    <MainLayout title={t('something_went_wrong')} homepageLink="/">
      <PageTitle>{t('site_name') + ' – ' + t('page_error')}</PageTitle>
      <img src="/images/error-robot-2.svg" className={styles.image} alt={t('page_not_found')} />
      <div className={styles.description}>
        <div>
          {t('something_went_wrong_description')}
        </div>
        <pre className={styles.code}>{error.message}</pre>
      </div>
      <div className={styles.description}>
        <Button size="medium" shadow={true} onClick={() => window.location.reload()}>{t('refresh_page')}</Button>
      </div>
    </MainLayout>
  );
};

export default ErrorPage;
