import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonsContainer, Button, LoadingButton, Input, PhoneInput, phonemasks } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import UserGroups from 'views/profile/UserGroups';
import ProfileCard from 'views/profile/ProfileCard';

import { addNotification } from 'store/notifications/notificationsSlice';
import {
  useGetUserDataQuery,
  useSetUserDataMutation,
  useGetUserGroupsQuery,
  useSetUserGroupsMutation
} from 'store/user/userApi';
import { useGetGroupsListQuery } from 'store/groups/groupsApi';
import { selectUserPermissions } from 'store/user/userSlice';
import getValidationSchema from 'data/validationSchema';

import ChangePasswordModal from './ChangePasswordModal';

import styles from './Profile.module.css';

interface FormValues {
  name: string,
  login: string
  phone: string
}

const fields: Array<keyof FormValues> = [ 'name', 'login', 'phone' ];

const UserProfile = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserPermissions);
  const canChangeUsers = userPermissions?.includes('users');
  const [ isShowChangePass, setShowChangePass ] = useState<boolean>(false);
  const [ newUserGroups, setNewUserGroups ] = useState<{ [key: string]: boolean }|null>(null);

  const { data: user, error: pageError } = useGetUserDataQuery(id, { skip: !id });
  const { data: groups } = useGetGroupsListQuery(undefined, { skip: !canChangeUsers });
  const { data: userGroups } = useGetUserGroupsQuery(id, { skip: !id });

  const [ saveUserData, { isLoading: isSaveDataLoading } ] = useSetUserDataMutation();
  const [ saveUserGroups, { isLoading: isSaveGoupsLoading } ] = useSetUserGroupsMutation();

  useEffect(() => {
    if (pageError && typeof pageError === 'object' && 'status' in pageError && 'name' in pageError) {
      if (pageError.status === 402) navigate('/subscribe');
      else if (pageError.status !== 'FETCH_ERROR' && pageError.name !== 'AbortError') navigate('/404');
    }
    if (!id) navigate('/users');
    // if (id && user && user.id === id) navigate('/profile', { replace: true });
    // eslint-disable-next-line
  }, [id, pageError]);

  const { register, handleSubmit, control, setValue, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: user && yupResolver(getValidationSchema(fields))
  });

  useEffect(() => {
    if (!user) return;
    fields.forEach(key => {
      if (key === 'phone') {
        // todo: change to universal format phone
        const x = (user.phone && user.phone.length >= 11) && user.phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/);
        setValue(key, x ? `+${x[1]}(${x[2]})-${x[3]}-${x[4]}-${x[5]}` : '' );
      } else {
        setValue(key, user[key]);
      }
    });
    // eslint-disable-next-line
  }, [user]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (newUserGroups) saveUserGroups({ id: user.id, groupids: Object.keys(newUserGroups).filter(key => !!newUserGroups[key]) });
    const result = await saveUserData({ id: user.id, ...data });

    if ('data' in result && result.data) {
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  return (
    <AdminLayout
      title={t('user_profile')}
      breadcrumbs={{ items: [ { name: t('settings'), to: '/profile', as: Link }, { name: t('user_profile') } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + t('user_profile')}</PageTitle>

      <div className={styles.cards}>
        <ProfileCard className={styles.cardUser} title={t('personal_data')}>
          <form id="profile_form" className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {!user ? (
              [1, 2, 3].map(key => (
                <div className={classNames(styles.input, 'skeleton-loader')} key={key} />
              ))
            ) : (
              fields.map(key => (
                <Controller
                  control={control}
                  name={key}
                  render={({ field: { value = '', ...rest } }) => {
                    if (key === 'phone') return (
                      <PhoneInput
                        id={key}
                        label={t(`fields.${key}`)}
                        placeholder={t(`placeholders.${key}`)}
                        viewStyle="secondary"
                        containerClass={styles.inputContainer}
                        labelClass={styles.label}
                        className={classNames(styles.input, styles.phone)}
                        error={!!errors?.[key]?.message}
                        errorMessage={errors?.[key]?.message && t('errors.' + errors?.[key]?.message)}
                        {...rest}
                        {...register}
                        value={value}
                        defaultMaskCode={(i18n.resolvedLanguage === 'en' || !i18n.resolvedLanguage) ? 'US' : i18n.resolvedLanguage.toUpperCase()}
                        masks={phonemasks}
                      />
                    );
                    else return (
                      <Input
                        id={key}
                        placeholder={t(`placeholders.${key}`)}
                        viewStyle="secondary"
                        inputMode={key === 'login' ? 'email' : 'text'}
                        className={styles.input}
                        {...rest}
                        {...register}
                        value={value}
                        readOnly={key === 'login'}
                        meta={{
                          containerClass: styles.inputContainer,
                          label: t(`fields.${key}`),
                          labelClass: styles.label,
                          error: !!errors?.[key]?.message,
                          errorMessage: errors?.[key]?.message && t('errors.' + errors?.[key]?.message),
                        }}
                      />
                    );
                  }}
                  key={key}
                />
              ))
            )}
          </form>
        </ProfileCard>

        <ProfileCard
          className={styles.cardLimits}
          title={
            <div className={styles.groupsHeader}>
              {t('user_groups')}
              {canChangeUsers && (
                <Button
                  as={Link}
                  viewStyle="text"
                  className={styles.groupsButton}
                  icon={<ReactSVG src="/icons/edit.svg" />}
                  iconClassName={styles.groupsButtonIcon}
                  to="/user-groups"
                />
              )}
            </div>
          }
        >
          <UserGroups
            groups={groups}
            selected={userGroups}
            onChange={setNewUserGroups}
          />
        </ProfileCard>

        <ButtonsContainer className={styles.buttons}>
          {/* <Button
            viewStyle="secondary"
            disabled={true}
          >{t('change_email')}</Button> */}
          <Button
            viewStyle="secondary"
            onClick={() => setShowChangePass(true)}
            className={styles.button}
          >
            {t('change_password')}
          </Button>
          <span className={styles.buttonPlaceholder} />
          <span className={styles.buttonPlaceholder} />
          <LoadingButton
            viewStyle="primary"
            className={styles.button}
            type="submit"
            form="profile_form"
            isLoading={isSaveGoupsLoading || isSaveDataLoading}
            disabled={!isValid}
          >
            {t('save')}
          </LoadingButton>
        </ButtonsContainer>
      </div>
      <ChangePasswordModal open={isShowChangePass} onClose={() => setShowChangePass(false)} />
    </AdminLayout>
  );
};

export default UserProfile;
