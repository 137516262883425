import { useState, useEffect, FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, Button, LoadingButton, FillFolder, FillingItemSkelet } from '@forma/forma-ui-kit';
import SelectVariableModal from 'views/FilesEditor/VariableSelectModal';

import { contragent_default_variables } from 'data/mock';
import { ICounteragent } from 'interfaces/counteragents.interface';
import { IVariableItem, IUserVariableItem, IVariablesHierarhyItem, IGroupWithSearchedTattrFolders } from 'interfaces/variables.interface';

import styles from './contragent-edit.module.css';

interface ContragentEditProps {
  type?: 'P'|'IP'|'E',
  counteragent?: ICounteragent,
  variablesGroups?: { [key: string]: IVariablesHierarhyItem },
  availableVariables?: { [key: string]: IVariableItem },
  userVariables?: { [key: string]: IUserVariableItem },
  isLoading: boolean,
  onSave: (values: { [key: string]: string }) => void,
  isSaveLoading: boolean,
  canDeleteVariables?: boolean,
  onChangeValid?: (isValid: boolean) => void,
  showSubmitButton?: boolean
}

interface SelectedVariablesItem { id: string, required: boolean }

const ContragentEdit: FC<ContragentEditProps> = ({
  type, counteragent, variablesGroups, availableVariables, userVariables, onSave, isSaveLoading, canDeleteVariables,
  onChangeValid, showSubmitButton = true
}) => {
  const { t } = useTranslation();
  const [ variables, setVariables ] = useState<SelectedVariablesItem[]>(type ? contragent_default_variables[type] : []);
  const [ isShowAdd, setShowAdd ] = useState(false);

  const mergeVariablesKeys = (prev: SelectedVariablesItem[], ids: string[], checked: boolean) => {
    if (!checked) return prev.filter(({ id }) => !ids.includes(id));
    return [ ...prev, ...ids.filter(id => !prev.find(item => item.id === id)).map(id => ({ id, required: false })) ];
  };

  const handleToggleVariable = (variable: IVariableItem, checked: boolean) => {
    setVariables(prev => mergeVariablesKeys(prev, [ variable.id ], checked));
  };

  const handleRemoveVariable = (variableId: string) => {
    setVariables(prev => prev.filter(item => item.id !== variableId));
    setValue(variableId, '');
    document.body.style.overflow = 'auto';
  };

  const onSubmit = (data: { [key: string]: string }) => {
    onSave(Object.keys(data).reduce((acc: { [key: string]: string }, current) => (
      data[current] ? { ...acc, [current]: data[current] } : acc
    ), {}));
  };

  const { register, control, trigger, setValue, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: counteragent?.attrValues
  });

  useEffect(() => {
    if (type && !counteragent) setVariables(contragent_default_variables[type]);
    else if (counteragent) {
      const attrKeys = Object.keys(counteragent.attrValues);

      setVariables(prev => {
        const next = mergeVariablesKeys(prev, attrKeys, true);

        attrKeys.forEach(key => {
          const item = next.find(({ id }) => String(id) === String(key));
          const value = counteragent.attrValues[key];
          if (item && value) setValue(key, value);
        });

        return next;
      });

      setTimeout(() => trigger(), 300);
    }
    // eslint-disable-next-line
  }, [type, counteragent]);

  useEffect(() => {
    if (onChangeValid) onChangeValid(isValid);
  }, [isValid, onChangeValid]);

  const tattrs = (variables.length && availableVariables && Object.keys(availableVariables).length && userVariables) ? (
    variables.reduce((acc: (IVariableItem|IUserVariableItem)[], current) => {
      const item = availableVariables[current.id] ? availableVariables[current.id] : userVariables[current.id];
      return item ? [ ...acc, { ...item, required: current.required ?? false } ] : acc;
    }, [])
  ) : null;

  const flatGroups = variablesGroups && Object.values(variablesGroups).flatMap(({ groups }) => groups);

  const foundTattrs = new Set();

  const groupTattr = flatGroups?.reduce((acc: IGroupWithSearchedTattrFolders[], group : Omit<IGroupWithSearchedTattrFolders, 'tattrFolder'>) => {
    const tattrInside = tattrs?.filter((elem) => {
      const isIncluded = group.items.includes(elem.id) || group.groupId === (elem as IUserVariableItem).folderid;
      const isAlreadyFound = foundTattrs.has(elem.id);
      if (isIncluded && !isAlreadyFound) {
        foundTattrs.add(elem.id);
        return true;
      }
      return false;
    });

    if (tattrInside?.length) {
      acc.push({ ...group, tattrFolder: [...tattrInside] });
    }

    return acc;
  }, []);

  return (
    <div className={styles.root}>
      <form id="contragent_edit_form" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.container}>
          {tattrs ? (
            groupTattr && groupTattr.map((elem) => (
              <FillFolder
                key={elem.name}
                name={elem.name}
                tattrs={elem.tattrFolder}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                values={counteragent?.attrValues}
                onRemove={canDeleteVariables ? handleRemoveVariable : undefined}
                showGroupName
              />
            ))
          ) : (
            <div className={styles.items}>
              {[...Array(10).keys()].map(key => (
                <FillingItemSkelet key={key} />
              ))}
            </div>
          )}
          <ButtonsContainer className={styles.fieldsButtons}>
            <Button
              onClick={() => setShowAdd(!isShowAdd)}
              viewStyle="secondary"
              shadow
              disabled={!(availableVariables && type)}
            >
              {t('add_data')}
            </Button>
          </ButtonsContainer>
        </div>

        {showSubmitButton && (
          <ButtonsContainer className={styles.buttons}>
            <LoadingButton
              type="submit"
              isLoading={isSaveLoading}
              // disabled={!(variables.length && availableVariables && isValid)}
              disabled={!isValid}
            >
              {counteragent ? t('save') : t('add_contragent')}
            </LoadingButton>
          </ButtonsContainer>
        )}
      </form>

      <SelectVariableModal
        open={isShowAdd}
        onClose={setShowAdd}
        variablesGroups={variablesGroups}
        availableVariables={availableVariables}
        userVariables={userVariables}
        onToggleVar={(id, checked) => handleToggleVariable(id, checked)}
        selectedVariables={variables && Object.values(variables).map(({ id }) => id)}
        icon={checked => checked && <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkedIcon} />}
        onChange={handleToggleVariable}
        onRemove={canDeleteVariables ? handleRemoveVariable : undefined}
      />
    </div>
  );
};

export default ContragentEdit;
