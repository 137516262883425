import { ITariffItem, ITariff, ITariffPrices, IPromocode, ISignatureRequest, ISignatureTariffItem } from 'interfaces/tariffs.interface';
import { baseApi } from '../store';

export interface ISetTariffParams {
  tariffId: string,
  patternId: string,
  addUsersCount?: number,
  addAttachmentsSize?: number
}

export const tariffsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTariffs: builder.query<ITariffItem[], void>({
      query: () => ({
        url: `/tariffs/`,
        method: 'GET'
      }),
      providesTags: ['Tariffs']
    }),
    getTariffsInstances: builder.query<ITariff[], void>({
      query: () => ({
        url: `/tariffs/instances`,
        method: 'GET'
      }),
      providesTags: ['TariffsHistory']
    }),
    getCurrentTariff: builder.query<ITariff, void>({
      query: () => ({
        url: `/tariffs/instances/current`,
        method: 'GET'
      }),
      providesTags: ['CurrentTariff']
    }),
    setTariff: builder.mutation<ITariff, ISetTariffParams>({
      query: data => ({
        url: `/tariffs/choose/`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Tariffs', 'CurrentTariff', 'Subscription', 'TariffPrices', 'TariffPromocode']
    }),
    setTariffUsers: builder.mutation<ITariff, { count: number }>({
      query: data => ({
        url: '/tariffs/adduser/',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['CurrentTariff']
    }),
    getTariffPrices: builder.query<ITariffPrices, ISetTariffParams>({
      query: ({ tariffId, patternId, addUsersCount, addAttachmentsSize }) => ({
        url: `/tariffs/choose/${tariffId}/${patternId}`,
        method: 'GET',
        params: {
          addUsersCount,
          addAttachmentsSize
        }
      }),
      providesTags: (result, error, { tariffId, patternId, addUsersCount, addAttachmentsSize }) => [
        { type: 'TariffPrices', id: `${tariffId}_${patternId}_${addUsersCount}_${addAttachmentsSize}` }
      ]
    }),

    checkPromocode: builder.query<IPromocode, string>({
      query: code => ({
        url: `/promocodes/${code}/`,
        method: 'GET'
      })
    }),
    checkPublicPromocode: builder.query<IPromocode, string>({
      query: code => ({
        url: `/promocodespublic/${code}/`,
        method: 'GET'
      })
    }),
    setPromocode: builder.mutation<IPromocode, string>({
      query: code => ({
        url: '/promocodes/',
        method: 'POST',
        body: { id: code }
      }),
      invalidatesTags: (result, error) => error ? [] : ['Tariffs', 'TariffPromocode', 'TariffPrices'],
    }),
    getCurrentPromocode: builder.query<IPromocode, void>({
      query: () => ({
        url: '/promocodes/current',
        method: 'GET',
      }),
      providesTags: ['TariffPromocode']
    }),

    getSignatureTariffs: builder.query<ISignatureTariffItem[], void>({
      query: () => ({
        url: '/workspace/signaturetariffs',
        method: 'GET',
      }),
      providesTags: ['SignatureTariffs']
    }),
    getSignaturePrices: builder.query<{ price: number }, { limit: number }>({
      query: ({ limit }) => ({
        url: '/workspace/requestsignatures',
        method: 'GET',
        params: {
          limit
        }
      }),
      providesTags: (result, error, { limit }) => [
        { type: 'SignatureLimit', id: `${limit}` }
      ]
    }),
    requestSignatureLimit: builder.mutation<ISignatureRequest, { limit: number }>({
      query: data => ({
        url: '/workspace/requestsignatures',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['SignatureLimit']
    }),
  })
});

export const {
  useGetTariffsQuery,
  useGetTariffsInstancesQuery,
  useGetCurrentTariffQuery,
  useSetTariffMutation,
  useSetTariffUsersMutation,
  useGetTariffPricesQuery,

  useLazyCheckPublicPromocodeQuery,
  useLazyCheckPromocodeQuery,
  useSetPromocodeMutation,
  useGetCurrentPromocodeQuery,

  useGetSignatureTariffsQuery,
  useGetSignaturePricesQuery,
  useRequestSignatureLimitMutation,
} = tariffsApi;
