import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import TariffSelect from 'views/tariffs/TariffsSelect';
import SubscriptionInfo from 'views/tariffs/SubscriptionInfo';
import Signature from 'views/tariffs/Signature';
import paymentService from 'helpers/paymentService';
import { deleteCookie } from 'helpers/cookies';
import webView from 'helpers/webview';
import { TRIAL_ID } from 'data/constants';

import {
  useGetCurrentTariffQuery,
  useGetTariffsQuery,
  useSetPromocodeMutation,
  useLazyCheckPromocodeQuery,
  useGetCurrentPromocodeQuery,
  useSetTariffMutation,
  ISetTariffParams,
  useRequestSignatureLimitMutation,
  useGetSignatureTariffsQuery
} from 'store/tariffs/tariffsApi';
import {
  ICreateInvoiceData,
  TInvoiceSource,
  useCreateInvoiceMutation,
  useGetAcquiringSubscriptionQuery
} from 'store/billing/billingApi';

import styles from './SelectTariff.module.css';

const SelectTariff = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const { data: tariffs } = useGetTariffsQuery();
  const { data: currentTariff } = useGetCurrentTariffQuery();
  const { data: subscription } = useGetAcquiringSubscriptionQuery();

  const [ setTariff, { isLoading: isSetTariffLoading } ] = useSetTariffMutation();
  const [ createInvoice, { data: createdBill, isLoading: isCreateInvoiceLoading } ] = useCreateInvoiceMutation();

  const [ getPromocodeInfo ] = useLazyCheckPromocodeQuery();
  const [ setPromocode ] = useSetPromocodeMutation();
  const { data: currentPromo } = useGetCurrentPromocodeQuery();

  const { data: signatureTariffs } = useGetSignatureTariffsQuery();
  const [ setSignature, { data: signatureRequest, isLoading: isSetSignatureLoading } ] = useRequestSignatureLimitMutation();

  useEffect(() => {
    paymentService.init();
  }, []);

  useEffect(() => {
    const code = localStorage.getItem('promocode');
    if (!code) return;
    handleSubmitPromo(code);
    localStorage.removeItem('promocode');
    // eslint-disable-next-line
  }, []);


  const handleSubmitPromo = async (code: string) => {
    const result = await getPromocodeInfo(code);
    if ('data' in result && result.data) {
      await setPromocode(code);
      return true;
    }

    return false;
  };

  const handleCreateInvoice = async (paymentId: string, billingData: Record<string, string>, source: TInvoiceSource) => {
    const data: ICreateInvoiceData = {
      id: paymentId,
      source: source,
      details: {
        ogrn: billingData.ogrn,
        companyname: billingData.companyname,
        inn: billingData.inn,
        kpp: billingData.kpp,
        address: billingData.address,
      }
    };

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.createInvoice(`${t('subscription.bill_document_name')}.pdf`, data);
      return true;
    } else {
      createInvoice(data).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${t('subscription.bill_document_name')}.pdf`;
        document.body.appendChild(a);
        a.click();

        return true;
      });
    }

    return false;
  };

  const handleSetTariff = async (params: ISetTariffParams) => {
    deleteCookie('tariff'); // оставить тут
    const res = await setTariff(params);
    if ('data' in res && res.data.id) return res.data.id;
    return null;
  };

  const handleDownloadInvoice = () => {
    if (!createdBill) return null;

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = createdBill;
    a.download = `${t('subscription.bill_document_name')}.pdf`;
    document.body.appendChild(a);
    a.click();
  };

  const handleSetSignature = async (params: { limit: number }) => {
    const res = await setSignature(params);
    if ('data' in res && res.data.id) return res.data.id;
    return null;
  };

  return (
    <AdminLayout
      title={t('subscription.subsription_and_payment')}
      breadcrumbs={{ items: [ { name: t('settings'), to: '/profile', as: Link }, { name: t('subscription.subsription_and_payment') } ] }}
      className={styles.root}
    >
      <PageTitle>{t('site_name') + ' – ' + t('subscription.subsription_and_payment')}</PageTitle>

      <SubscriptionInfo tariff={currentTariff} subscription={subscription} />

      <TariffSelect
        current={currentTariff}
        tariffs={tariffs}
        subscription={subscription}
        promo={{
          info: currentPromo,
          onSubmit: handleSubmitPromo,
          onCancel: () => {},
        }}
        initialData={{
          paymentOpen: !!state?.paymentOpen,
          defaultMethod: state?.paymentMethod ?? 'card',
        }}
        actions={{
          onSetTariff: handleSetTariff,
          isSetTariffLoading: isSetTariffLoading,
          onCreateInvoice: handleCreateInvoice,
          isCreateInvoiceLoading: isCreateInvoiceLoading,
          onDownloadInvoice: handleDownloadInvoice
        }}
      />

      <Signature
        isAllowed={currentTariff?.id !== TRIAL_ID && currentTariff?.isActive}
        tariffs={signatureTariffs}
        current={signatureRequest}
        actions={{
          onSetSignature: handleSetSignature,
          isSetSignatureLoading: isSetSignatureLoading,
          onCreateInvoice: handleCreateInvoice,
          isCreateInvoiceLoading: isCreateInvoiceLoading,
          onDownloadInvoice: handleDownloadInvoice
        }}
      />
    </AdminLayout>
  );
};

export default SelectTariff;
