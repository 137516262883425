import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { ButtonsContainer, Button, Modal, FillFolder } from '@forma/forma-ui-kit';
import { ORG_OGRN_LENGTH } from 'data/constants';

import { IVariableItem } from 'interfaces/variables.interface';

import styles from './payment-fields.module.css';

interface PaymentFieldsProps {
  data: { [key: string]: string },
  tattrs: IVariableItem[],
  onSubmit: (data: { [key: string]: string }) => void,
  onCancel?: () => void
}

const PaymentFieldsGroup: FC<PaymentFieldsProps> = ({ data, tattrs, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const { register, control, setValue, watch, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: data
  });

  const ogrn = watch('ogrn');

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <FillFolder
        tattrs={ogrn?.length === ORG_OGRN_LENGTH ? tattrs : tattrs.filter(item => item.id !== 'kpp')}
        register={register}
        errors={errors}
        control={control}
        setValue={setValue}
        itemsClassName={styles.modalFields}
      />
      <ButtonsContainer className={styles.formButtons}>
        <Button viewStyle="tertiary" onClick={onCancel}>{t('cancel')}</Button>
        <Button type="submit" viewStyle="primary">{t('save')}</Button>
      </ButtonsContainer>
    </form>
  );
};

const PaymentFields: FC<PaymentFieldsProps> = ({ data, tattrs, onSubmit }) => {
  const { t } = useTranslation();
  const [ isFieldsModalOpen, setFieldsModalOpen ] = useState<boolean>(false);

  return (
    <div>
      {data.companyname && (
        <div className={styles.address}>
          <div className={styles.addressContent}>
            <div className={classNames(styles.addressItem, styles.wide)}>
              {!data.address &&
                <ReactSVG src="/icons/attention.svg" className={styles.addresIcon} wrapper="span" />
              }
              <span className={styles.addressLabel}>{t('address')}: </span>
              <span className={styles.addressValue}>{data.address}</span>
            </div>
            <div className={styles.addressItem}>
              {!data.inn &&
                <ReactSVG src="/icons/attention.svg" className={styles.addresIcon} wrapper="span" />
              }
              <span className={styles.addressLabel}>{t('inn')}: </span>
              <span className={styles.addressValue}>{data.inn}</span>
            </div>
            <div className={styles.addressItem}>
              {!data.ogrn &&
                <ReactSVG src="/icons/attention.svg" className={styles.addresIcon} wrapper="span" />
              }
              <span className={styles.addressLabel}>{t('ogrn')}: </span>
              <span className={styles.addressValue}>{data.ogrn}</span>
            </div>
            {data.ogrn?.length === ORG_OGRN_LENGTH && (
              <div className={styles.addressItem}>
                {!data.kpp &&
                  <ReactSVG src="/icons/attention.svg" className={styles.addresIcon} wrapper="span" />
                }
                <span className={styles.addressLabel}>{t('kpp')}: </span>
                <span className={styles.addressValue}>{data.kpp}</span>
              </div>
            )}
          </div>

          <Button
            className={styles.addressEdit}
            viewStyle="text"
            icon={<ReactSVG src="/icons/edit.svg" className={styles.addressEditIcon} wrapper="span" />}
            onClick={() => setFieldsModalOpen(true)}
          />
        </div>
      )}
      <Modal
        width="100%"
        maxWidth="985px"
        open={isFieldsModalOpen}
        onClose={() => setFieldsModalOpen(false)}
        title={t('subscription.edit_data_for_billing')}
        titleClassName={styles.modalTitle}
        closeOnDocumentClick={false}
      >
        <div className={styles.modalContent}>
          <PaymentFieldsGroup
            data={data}
            tattrs={tattrs}
            onSubmit={data => { onSubmit(data); setFieldsModalOpen(false); }}
            onCancel={() => setFieldsModalOpen(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PaymentFields;
