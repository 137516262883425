import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, useDebounce } from '@forma/forma-ui-kit';
import { IPromocode } from 'interfaces/tariffs.interface';

import styles from './promo-input.module.css';

interface IPromoInputProps {
  info: IPromocode|null,
  isError: boolean,
  onChange: (code: string) => void
}

const PromoInput: FC<IPromoInputProps> = ({ info, isError, onChange }) => {
  const { t } = useTranslation();
  const [ value, setValue ] = useState('');

  const handleChangeValue = useDebounce((value: string) => {
    onChange(value);
  }, 500);

  return (
    <div className={styles.root}>
      <Input
        name="promocode"
        placeholder={t('subscription.enter_promocode')}
        onChange={e => { setValue(e.target.value); handleChangeValue(e.target.value); }}
        value={value}
        data-testid="promocode"
        meta={{
          error: isError,
          errorMessage: t('subscription.promocode_not_valid')
        }}
      />
      {(info && !isError) &&
        <div className={styles.notice}>
          {info.description}
        </div>
      }
    </div>
  );
};

export default PromoInput;
