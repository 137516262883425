import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './logo.module.css';

interface LogoProps {
  showText?: boolean,
  className?: string,
  textClassName?: string,
  link?: string,
  asLink?: boolean
}

interface LogoHOCProps {
  link?: string,
  children: React.ReactNode,
  className: string,
  asLink?: boolean
}

const LogoHOC = ({ link, children, className, asLink }: LogoHOCProps) => {
  if (!link) return <div className={className}>{children}</div>;
  if (asLink) return <a href={link} className={className}>{children}</a>;
  return <Link to={link} className={className}>{children}</Link>;
};

const Logo = ({ showText, className, textClassName, link, asLink }: LogoProps) => {
  const { t, i18n } = useTranslation();


  return (
    <LogoHOC link={link} asLink={asLink} className={classNames(styles.link, className)}>
      <div className={styles.imageWrap}>
        <img
          className={styles.image}
          src="/images/small_logo.svg"
          alt={t('main_company_name') ?? ''}
        />
        <ReactSVG
          className={styles.imageHover}
          src="/images/small_logo_animated.svg"
        >
          {t('main_company_name') ?? ''}
        </ReactSVG>
      </div>
      {showText &&
        <ReactSVG
          className={classNames(styles.imageText, textClassName)}
          src={`/images/title_${i18n.resolvedLanguage ? i18n.resolvedLanguage : i18n.language}.svg`}
          wrapper="span"
        />
      }
    </LogoHOC>
  );
};

export default Logo;