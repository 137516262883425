import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonsContainer } from '@forma/forma-ui-kit';

import { IUser } from 'interfaces/users.interface';

import styles from './profile-limits.module.css';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface ProfileLimitsProps {
  user?: IUser
}

interface LimitsUserProps {
  title: ReactNode,
  count: number,
  max: number,
}

const LimitsItem = ({ title, count, max }: LimitsUserProps) => {
  const { t } = useTranslation();
  const percent = (count && max) ? (count / max) * 100 : 0;

  return (
    <div className={styles.item}>
      <div className={styles.itemHeader}>
        <div className={styles.itemTitle}>{title}</div>
        <div className={styles.itemValue}>{t('count_from_max', { count, max })}</div>
      </div>
      <div className={classNames(styles.progress, percent >= 90 && styles.error)}>
        <div className={styles.bar} style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
};

const ProfileLimits = ({ user }: ProfileLimitsProps) => {
  const { t } = useTranslation();

  if (!user) return null;

  return (
    <div className={styles.root}>
      <LimitsItem
        title={t('created_pep_documents')}
        count={user.workspace.signature_used ?? 0}
        max={user.workspace.signature_available ?? 0}
      />
      <LimitsItem
        title={<Trans i18nKey="used_archive_space" />}
        count={user.workspace.attachments_used ?? 0}
        max={user.workspace.attachments_available ?? 0}
      />
      <ButtonsContainer className={styles.buttons}>
        <Button
          as={Link}
          viewStyle="secondary"
          className={styles.button}
          to="/select-tariff"
          fullWidth
        >
          {t('subscription.change_tariff')}
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default ProfileLimits;
