import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Table, TableRow, TableCell } from '@forma/forma-ui-kit';
import { ORG_OGRN_LENGTH } from 'data/constants';

import { IBillingInfo, IPaymentCardItem, IPaymentSubscription } from 'interfaces/billing.interface';

import styles from './saved-payment-methods.module.css';

interface SavedPaymentMethodsProps {
  subscription?: IPaymentSubscription,
  cards?: IPaymentCardItem[],
  billingInfo?: IBillingInfo,
  isLoading?: boolean
}

const SavedPaymentMethods: FC<SavedPaymentMethodsProps> = ({
  cards, billingInfo, isLoading
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Table className={styles.table}>
        {cards?.map(({ id, cardLastFour }) => {
          // const isActive = subscription?.card?.id === id;
          return (
            <TableRow key={id}>
              <TableCell className={styles.cellIcon}>
                <ReactSVG className={styles.icon} src="/icons/payment-card.svg" wrapper="span" />
              </TableCell>
              <TableCell className={styles.cellName}>
                ****{cardLastFour}
              </TableCell>
            </TableRow>
          );
        })}
        {billingInfo?.companyname && (
          <TableRow>
            <TableCell className={styles.cellIcon}>
              <ReactSVG className={styles.icon} src={`/icons/payment-${billingInfo.ogrn?.length === ORG_OGRN_LENGTH ? 'org' : 'ip'}.svg`} wrapper="span" />
            </TableCell>
            <TableCell className={styles.cellName}>
              {billingInfo.companyname}
            </TableCell>
          </TableRow>
        )}
        {(!isLoading && !cards?.length && !billingInfo?.companyname) && (
          <TableRow>
            <TableCell className={styles.noItems}>
              {t('subscription.saved_payment_methods_empty')}
            </TableCell>
          </TableRow>
        )}
      </Table>
    </div>
  );
};

export default SavedPaymentMethods;
