import { FC, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { FillFolder, phonemasks } from '@forma/forma-ui-kit';
import formatPhoneNumber from 'helpers/formatPhoneNumber';
import { paymentMethodsData } from 'data/mock';
import { INN_OGRN_LENGTH, ORG_KPP_LENGTH, ORG_OGRN_LENGTH } from 'data/constants';
import PaymentFields from './PaymentFields';

import { TPaymentMethod } from 'interfaces/billing.interface';
import { IPhoneMask } from 'interfaces/common.interface';
import { useGetBillingInfoQuery } from 'store/billing/billingApi';
import { selectUserData } from 'store/user/userSlice';

import styles from './payment-methods.module.css';

interface PaymentMethodsProps {
  selected?: TPaymentMethod,
  onSelect: (id: TPaymentMethod) => void,
  card?: {
    disabled?: boolean,
  },
  invoice?: {
    disabled?: boolean,
    onFill?: (data: { [key: string]: string }|null) => void,
  },
}

interface PaymentMethodsItemProps {
  id: TPaymentMethod,
  disabled?: boolean,
  selected?: boolean,
  onClick: () => void,
  icons?: string[],
  userLogin?: string,
  fieldsSlot?: ReactNode
}

const PaymentMethodsItem: FC<PaymentMethodsItemProps> = ({ id, disabled, selected, onClick, icons, userLogin, fieldsSlot }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.item}>
      <div
        className={classNames(styles.itemRow, selected && styles.selected, disabled && styles.disabled)}
        onClick={onClick}
        tabIndex={0}
      >
        <div className={styles.text}>
          <div className={styles.name}>{t(`payments.${id}.title`)}</div>
          <div className={styles.description}>{userLogin && t(`payments.${id}.description`, { email: userLogin })}</div>
        </div>
        {icons?.length ? (
          <div className={styles.icons}>
            {icons.map(icon => (
              <img className={styles.icon} src={icon} width="33px" height="32px" alt="" key={icon} />
            ))}
          </div>
        ) : null}
      </div>
      {fieldsSlot}
    </div>
  );
};

const PaymentMethods: FC<PaymentMethodsProps> = ({
  selected, onSelect, card, invoice
}) => {
  const { i18n } = useTranslation();
  const user = useSelector(selectUserData);
  const { data: savedData } = useGetBillingInfoQuery(null);

  const defaultMaskCode = (i18n.resolvedLanguage && i18n.resolvedLanguage !== 'en') ? i18n.resolvedLanguage.toUpperCase() : 'US';
  const phoneMask = phonemasks.find(({ iso }: IPhoneMask) => iso === defaultMaskCode);

  const [ paymentData, setPaymentData ] = useState<{ [key: string]: string }>({});

  const { register, control, setValue, watch, formState: { errors, isValid } } = useForm({
    mode: 'onChange'
  });

  const handleSubmitFilling = (data: { [key: string]: string }|null) => {
    if (data && data.ogrn?.length !== ORG_OGRN_LENGTH) delete(data.kpp);
    if (invoice?.onFill) invoice.onFill(data);
  };

  useEffect(() => {
    const subscription = watch(values => {
      setPaymentData(prev => ({ ...prev, ...values }));
    });
    return () => subscription.unsubscribe();
    //eslint-disable-next-line
  }, [ watch ]);


  useEffect(() => {
    const isFormValid = isValid && (paymentData.ogrn?.length === INN_OGRN_LENGTH
      || (paymentData.ogrn?.length === ORG_OGRN_LENGTH && paymentData.kpp?.length === ORG_KPP_LENGTH)
    );

    if (isFormValid && paymentData.ogrn) handleSubmitFilling(paymentData);
    else handleSubmitFilling(null);
    //eslint-disable-next-line
  }, [paymentData.companyname, paymentData.ogrn, paymentData.kpp, isValid]);

  // && paymentData.ogrn?.length === 15

  useEffect(() => {
    if (user?.phone && phoneMask) setValue('phone', formatPhoneNumber(String(user.phone), phoneMask.code+phoneMask.mask), { shouldValidate: true });
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (savedData) Object.entries(savedData).forEach(([ key, value ]) => {
      if (key === 'phone' && phoneMask) setValue('phone', formatPhoneNumber(value, phoneMask.code+phoneMask.mask), { shouldValidate: true });
      else setValue(key, value, { shouldValidate: true });
    });
    // eslint-disable-next-line
  }, [savedData]);

  return (
    <div className={styles.root}>
      <PaymentMethodsItem
        id="card"
        disabled={card?.disabled}
        selected={selected === 'card'}
        onClick={() => onSelect('card')}
        icons={paymentMethodsData.card.icons}
        userLogin={user?.login}
      />
      <PaymentMethodsItem
        id="invoice"
        disabled={invoice?.disabled}
        selected={selected === 'invoice'}
        onClick={() => onSelect('invoice')}
        userLogin={user?.login}
        fieldsSlot={(selected === 'invoice') && (
          <div className={styles.fields}>
            <FillFolder
              tattrs={[
                ...paymentMethodsData.invoice.tattrsBase,
                ...paymentMethodsData.invoice.tattrsFull.map(item => ({ ...item, hidden: true, required: item.id !== 'kpp' }))
              ]}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
            />

            <PaymentFields
              data={paymentData}
              tattrs={paymentMethodsData.invoice.tattrsFull}
              onSubmit={data => {
                Object.keys(data).forEach(key => setValue(key, data[key]));
                handleSubmitFilling(data);
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default PaymentMethods;
