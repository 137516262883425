import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';
import FillRequestModal from 'views/Guide/FillRequestModal';

import { useSetOnboardingMutation } from 'store/user/userApi';

import styles from './send-templates-notification.module.css';

const SendTemplatesNotification = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [ isShow, setShow ] = useState(false);
  const [ isSendOpen, setSendOpen ] = useState(false);
  const [ isSended, setSended ] = useState(false);

  const [ setOnboardingItem ] = useSetOnboardingMutation();

  useEffect(() => {
    const isShow = !!localStorage.getItem('setting_templates_notification');
    if (isShow) setShow(true);
  }, []);

  const handleCloseNotification = () => {
    setOnboardingItem({ setting_templates_notification: true });
    localStorage.removeItem('setting_templates_notification');
    setShow(false);
  };

  const handleCloseSending = () => {
    setSendOpen(false);
    if (isSended) handleCloseNotification();
  };

  if (!isShow || pathname === '/guide') return null;

  return (
    <div className={classNames(styles.root, 'success')}>
      <div className={styles.content}>
        <ReactSVG className={styles.icon} src="/icons/documents-list.svg" wrapper="div" />
        <div className={styles.text}>
          <div className={styles.title}><Trans i18nKey="send_templates_title" components={{ span: <span /> }} /></div>
          <div className={styles.description}>{t('send_templates_description')}</div>
        </div>
      </div>

      <div className={styles.buttons}>
        <Button
          className={styles.button}
          size="extraSmall"
          onClick={() => setSendOpen(true)}
        >
          {t('send_templates')}
        </Button>
        <Button
          viewStyle="text"
          className={styles.close}
          size="extraSmall"
          iconClassName={styles.closeIcon}
          icon={<ReactSVG src="/icons/close_thin.svg" wrapper="span" />}
          onClick={handleCloseNotification}
        />
        <FillRequestModal
          open={isSendOpen}
          onClose={handleCloseSending}
          onSend={() => setSended(true)}
        />
      </div>
    </div>
  );
};

export default SendTemplatesNotification;
