import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  ButtonsContainer,
  Button,
  Tabs,
  DocumentName,
  FillingItemSkelet,
  calculateTotalValues,
  ThemeContext,
  mapTotalForCalc,
  // mapSidesToFilling,
} from '@forma/forma-ui-kit';
import ButtonMulti from 'components/ButtonMulti';
import { analytics } from 'helpers/analytics';
import DocumentsRequest from './DocumentsRequest';
import SelectOwnerModal from './SelectOwnerModal';
import SendSignModal from './SendSignModal';
import FillDocumentSide, { clearSideDataByCarticleId } from './FillDocumentSide';
import mapSidesToFilling from './mapSidesToFilling';
import { calculateSidesFillingCounts, checkSideValid, clearSidesForExternalFill } from './utils';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectOnboarding } from 'store/user/userSlice';
import { setOnboardingModal } from 'store/common/commonSlice';
import { ITemplateRenderData } from 'store/templates/templatesApi';
import { ISidesListEditData, ISidesListSendingData, ITemplateBlocksTree, ITemplateSide, TFormatDocument } from 'interfaces/templates.interface';
import { IExternalFillItem } from 'interfaces/externalfill.interface';

import styles from './fill-document.module.css';

interface FillDocumentProps {
  className?: string,
  name?: string,
  sides?: ITemplateSide[],
  blocksTree?: ITemplateBlocksTree,
  onClickDownload: (
    name: string,
    sideValues: ITemplateRenderData['sideValues'],
    formatDocument: TFormatDocument,
    isChanged: boolean
  ) => void,
  onExternalFill?: (
    name: string,
    sideValues: IExternalFillItem['sideValues'],
    attachmentNames: string[],
    ownerId: string,
    signer?: {
      name: string,
      phone: string
    },
    files?: File[]
  ) => Promise<boolean>,
  isSendSignLoading?: boolean,
  isExternalFillLoading?: boolean,
  isDownloadLoading?: boolean,
  valueSides?: ISidesListEditData,
  showNameInput?: boolean,
  showHead?: boolean,
  showFillMessage?: boolean,
  setIsFillingValid?: (statusValid : boolean) => void;
  setSidesDataForInnerRenderButton?: (data: ITemplateRenderData['sideValues']) => void,
  setNameFileForInnerRender? : (name: string) => void,
  showButtons?: boolean,
  onSetChanged?: () => void,
  checkAllowSelect?: (mode: 'sign'|'external') => boolean,
  formats?: TFormatDocument[],
  permissions?: string[]
}

const cleanSideDataForRender = (sidesData: ISidesListEditData) => (
  Object.values(sidesData).map(({ tableValues, tattrErrors, tattrValues, isValid, ...rest }) => ({
    tattrValues: Object.keys(tattrValues).reduce((acc: { [key: string]: string }, key) => (
      tattrValues[key] ? { ...acc, [key]: tattrValues[key].replace(/<br \/>$/, '') } : acc
    ), {}),
    tableValues: tableValues?.map(table => ({ ...table, products: Object.values(table.products) })),
    ...rest
  }))
);

const FillDocument: FC<FillDocumentProps> = ({
  className, name: defaultName = '', sides, blocksTree, onClickDownload, isDownloadLoading,
  onExternalFill, isExternalFillLoading, permissions,
  valueSides, showNameInput = false, showHead = true, showFillMessage = true, showButtons = true, formats,
  setIsFillingValid, setSidesDataForInnerRenderButton, setNameFileForInnerRender, onSetChanged, checkAllowSelect
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onboarding = useAppSelector(selectOnboarding);
  const [ currentSideId, setCurrentSideId ] = useState('');
  const [ sidesData, setSidesData ] = useState<ISidesListEditData|undefined>(valueSides);
  const [ isChanged, setIsChanged ] = useState<boolean>(false);
  const [ name, setName ] = useState<string>(defaultName);
  const [ mode, setMode ] = useState<'fill'|'sign'|'external'>('fill');
  const [ isSendModalOpen, setSendModalOpen ] = useState<boolean>(false);
  const [ sendingData, setSendingData ] = useState<ISidesListSendingData|null>(null);
  const [ requestDocuments, setRequestDocuments ] = useState<string[]>([]);

  const { currencies, lang } = useContext(ThemeContext);
  const currency = currencies[lang];

  const currentSideIndex = sides?.findIndex(({ id }) => id === currentSideId) ?? -1;

  useEffect(() => {
    if (onboarding && !onboarding.fill_template_modal) dispatch(setOnboardingModal('fill_template_modal'));
    // eslint-disable-next-line
  }, [onboarding]);

  useEffect(() => {
    if (defaultName) setName(defaultName);
  }, [defaultName]);

  useEffect(() => {
    if (valueSides)
      setSidesData(valueSides);
    else if (sides)
      setSidesData(
        sides.reduce((acc, { id, tables, tattrFolders, carticles }) => ({
          ...acc,
          [id]: {
            id,
            tattrErrors: {},
            tattrValues: {},
            tableValues: !!tables?.length ? (
              tables.map(({ id, total, showTotalRow }) => ({
                tableId: id,
                products: [],
                total: calculateTotalValues([], mapTotalForCalc(total, showTotalRow), currency.code),
                customProductsIds: []
              }))
            ) : null,
            articleValues: !!carticles?.length ? carticles.map(({ id, variants }) => (
              { id, value: variants.filter(({ default: byDefault }) => byDefault).map(({ id }) => id) }
            )) : null,
            isValid: !tattrFolders.length
          },
        }), {})
      );

    if (sides) setCurrentSideId(sides[0].id);
  }, [sides, valueSides, currency]);

  const handleChangeName = (name: string) => {
    setName(name);
    if (setNameFileForInnerRender) {
      setNameFileForInnerRender(name);
    }
  };

  const handleClickDownload = (formatDocument: TFormatDocument = 'pdf') => {
    analytics.dowloadDocument();
    if (!sidesData) return;
    onClickDownload(
      name,
      cleanSideDataForRender(sidesData),
      formatDocument,
      isChanged
    );
  };

  useEffect(() => {
    if (setSidesDataForInnerRenderButton === undefined || !sidesData) return;
    setSidesDataForInnerRenderButton(cleanSideDataForRender(sidesData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidesData]);

  const handleShowSelect = (mode: 'sign'|'external') => {
    if (checkAllowSelect && checkAllowSelect(mode) === false) return null;

    if (onboarding && !onboarding.select_external_filling_modal) {
      if (mode === 'external') dispatch(setOnboardingModal('select_external_filling_modal'));
    }

    setMode(mode);
    if (!sides) return;

    setSendingData(sides.reduce((acc, side) => {
      // if (side.id !== currentSideId)
      //   return ({ ...acc, [side.id]: { tattrs: [], tables: [], articles: [] } });

      const preSelectedTattrs = side?.tattrFolders.reduce((acc: string[], { tattrs }) => ([
        ...acc,
        ...tattrs.reduce((acc: string[], { id }) => ((sidesData && sidesData[side.id].tattrValues[id]) ? acc : [ ...acc, id ]), [])
      ]), []);

      const preSelectedTables = side?.tables.reduce((acc: string[], { id }) => {
        if (!sidesData) return acc;
        const table = sidesData[side.id].tableValues.find(table => id === table.tableId);
        if (!table || Object.keys(table.products).length) return acc;
        return [ ...acc, table.tableId ];
      }, []);

      const preSelectedArticles = side?.carticles.reduce((acc: string[], { id }) => {
        if (!sidesData) return acc;
        const carticle = sidesData[side.id].articleValues.find(article => id === article.id);
        if (!carticle || carticle.value.length) return acc;
        return [ ...acc, carticle.id ];
      }, []);

      setSidesData(prev => {
        if (!prev || !prev[side.id] || !sides || !sides[currentSideIndex]) return prev;
        let sideData = prev[side.id];
        preSelectedArticles.forEach(vid => {
          sideData = clearSideDataByCarticleId(sideData, sides[currentSideIndex].carticles, vid);
        });

        return {
          ...prev,
          [side.id]: sideData
        };
      });

      return ({ ...acc, [side.id]: { tattrs: preSelectedTattrs, tables: preSelectedTables, articles: preSelectedArticles } });
    }, {}));
  };

  const handleHideSelect = () => {
    setMode('fill');
  };

  const handleSubmitOwner = async (ownerId: string) => {
    if (!sidesData || !onExternalFill || !sendingData) return;

    const isSended = await onExternalFill(
      name,
      clearSidesForExternalFill(sidesData, sendingData),
      requestDocuments,
      ownerId
    );

    if (isSended) setSendModalOpen(false);
  };

  const handleSendSign = async (ownerId: string, name: string, signer: { name: string, phone: string }, files: File[]) => {
    if (!sidesData || !onExternalFill || !sendingData) return;

    const isSended = await onExternalFill(
      name,
      clearSidesForExternalFill(sidesData, sendingData),
      [],
      ownerId,
      signer,
      files
    );

    if (isSended) setSendModalOpen(false);
  };

  const selectedVariants = sidesData && Object.values(sidesData)?.reduce((acc: string[], { articleValues }) => {
    if (!articleValues) return acc;
    return [ ...acc, ...articleValues.reduce((acc: string[], { value }) => ([ ...acc, ...value ]), []) ];
  }, []);

  const sidesToFilling = sides && mapSidesToFilling(sides, blocksTree, selectedVariants);
  const sidesFillingCount = sidesToFilling && calculateSidesFillingCounts(sidesToFilling, sidesData, sendingData, ['external', 'sign'].includes(mode));

  const isFillingValid = !!(name.length >= 3 && sidesData && sidesToFilling && Object.keys(sidesData).length === sides.length) &&
    Object.values(sidesData).every(sideData => {
      const side = sidesToFilling.find(side => String(side.id) === String(sideData.id));
      if (!side) return false;
      return checkSideValid(side, sideData);
    });

  useEffect(() => {
    if (setIsFillingValid) setIsFillingValid(isFillingValid);
  }, [setIsFillingValid, isFillingValid]);

  const emptyFieldsCount = sidesFillingCount?.reduce((acc, { count, total }) => acc+total-count, 0);

  return (
    <div className={classNames(styles.root, className)}>
      {showHead && (
        <div className={styles.head}>
          <DocumentName
            name={name}
            showNameInput={showNameInput}
            onSubmit={handleChangeName}
          />
          {mode === 'external' && (
            <Button
              viewStyle="textPrimary"
              className={styles.headButton}
              onClick={() => dispatch(setOnboardingModal('send_external_filling_modal'))}
            >
              {t('guide.what_is_external_fill')}
            </Button>
          )}
          {mode === 'fill' && (
            <Button
              viewStyle="textPrimary"
              className={styles.headButton}
              onClick={() => dispatch(setOnboardingModal('filling_modal'))}
            >
              {t('guide.howto_create_document')}
            </Button>
          )}
        </div>
      )}
      <Tabs
        contentClassName="fill-document-content" // class for styling and onboarding */}
        dontRemoveContent
        current={currentSideId}
        onChange={setCurrentSideId}
        items={sidesToFilling?.length ? (
          sidesToFilling.map(({ id, name, tattrFolders, tables, carticles }, index) => {
            return {
              id,
              name,
              className: styles.tabButton,
              testId: `filling_tab_${index}`,
              bage: {
                content: sidesFillingCount && (
                  <span className={classNames(
                    styles.bage,
                    (id !== currentSideId) && (
                      sidesFillingCount[index].total > sidesFillingCount[index].count ? styles.error : styles.success
                    )
                  )}>
                    {sidesFillingCount[index].count}/{sidesFillingCount[index].total}
                  </span>
                )
              },
              children: (
                <FillDocumentSide
                  id={id}
                  name={name}
                  tattrFolders={tattrFolders}
                  tables={tables}
                  carticles={carticles}
                  sideData={sidesData && sidesData[id]}
                  onChangeSidesData={setSidesData}
                  sendingData={sendingData && sendingData[id]}
                  onChangeSendingData={setSendingData}
                  isShowSelect={['external', 'sign'].includes(mode)}
                  onSetChanged={() => {
                    setIsChanged(true);
                    if (onSetChanged) onSetChanged();
                  }}
                  key={id}
                />
              )
            };
          })
        ) : (
          [
            {
              id: '0',
              name: <span style={{ display: 'block', width: '130px' }} />,
              children: (
                <div className={styles.items}>
                  {[...Array(10)].map((key, index) => (
                    <FillingItemSkelet key={index} />
                  ))}
                </div>
              )
            }
          ]
        )}
      />
      {['external'].includes(mode) && (
        <div id="add_sending_documents" className={styles.documents}> {/* class for onboarding */}
          <DocumentsRequest
            items={requestDocuments}
            onChange={setRequestDocuments}
          />
        </div>
      )}
      {!!(sides && emptyFieldsCount && showButtons) && (
        <div className={styles.notice}>
          <div className={styles.noticeButtons}>
            {(sides.length > 1 && currentSideIndex > 0) && (
              <Button
                className={styles.noticeButton}
                viewStyle="secondary"
                onClick={() => setCurrentSideId(sides[currentSideIndex-1].id)}
                icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
                shadow
              >
                {sides[currentSideIndex-1].name}
                {sidesFillingCount && (
                  <span className={classNames(
                    styles.noticeBage,
                    sidesFillingCount[currentSideIndex-1].total > sidesFillingCount[currentSideIndex-1].count
                      ? styles.error
                      : styles.success
                  )}>
                    {sidesFillingCount[currentSideIndex-1].count}/{sidesFillingCount[currentSideIndex-1].total}
                  </span>
                )}
              </Button>
            )}
          </div>
          <div className={styles.noticeContent}>
            <div className={styles.emptyFields}>
              {['external', 'sign'].includes(mode)
                ? `${t('count_fields', { count: emptyFieldsCount })} ${t('not_selected_not_filled')}`
                : `${t('not_filled')} ${t('count_fields', { count: emptyFieldsCount })}`
              }
            </div>
            {showFillMessage && <div>{t('fill_its_or_select_for_external_fill')}</div>}
          </div>
          <div className={styles.noticeButtons}>
            {(sides.length > 1 && currentSideIndex < sides.length-1) && (
              <Button
                className={styles.noticeButton}
                viewStyle="secondary"
                onClick={() => setCurrentSideId(sides[currentSideIndex+1].id)}
                icon={<ReactSVG src="/icons/arrow-right.svg" wrapper="span" />}
                reversed
                shadow
              >
                {sides[currentSideIndex+1].name}
                {sidesFillingCount && (
                  <span className={classNames(
                    styles.noticeBage,
                    sidesFillingCount[currentSideIndex+1].total > sidesFillingCount[currentSideIndex+1].count
                      ? styles.error
                      : styles.success
                  )}>
                    {sidesFillingCount[currentSideIndex+1].count}/{sidesFillingCount[currentSideIndex+1].total}
                  </span>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
      {(sides && showButtons) && (
        ['external', 'sign'].includes(mode) ? (
          <ButtonsContainer className={classNames(styles.buttons, styles.mobileInline, 'fill-document-buttons')}> {/* class for onboarding */}
            <Button
              className={styles.button}
              viewStyle="secondary"
              onClick={handleHideSelect}
              shadow
            >
              {t('go_back')}
            </Button>
            <Button
              id="send_filling"
              className={styles.button}
              viewStyle="primary"
              disabled={!!emptyFieldsCount}
              onClick={() => setSendModalOpen(true)}
              shadow
            >
              {t('next')}
            </Button>
            <SelectOwnerModal
              open={isSendModalOpen && mode === 'external'}
              onClose={() => setSendModalOpen(false)}
              onSubmit={handleSubmitOwner}
              isLoading={isExternalFillLoading}
            />
            <SendSignModal
              open={isSendModalOpen && mode === 'sign'}
              onClose={() => setSendModalOpen(false)}
              onSubmit={handleSendSign}
              isLoading={isExternalFillLoading}
              name={name}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer className={classNames(styles.buttons, 'fill-document-buttons')}> {/* class for onboarding */}
            <ButtonMulti
              viewStyle="secondary"
              disabled={!isFillingValid}
              isLoading={isDownloadLoading}
              rootClassName={styles.buttonMulti}
              reversed
              buttons={formats?.map(format => ({
                id: `save_filled_button_${format}`,
                type: 'submit',
                onClick: () => handleClickDownload(format),
                icon: <ReactSVG src={`/icons/file_types/${format}.svg`} wrapper="span" />,
                children: t(`download_${format}`)
              })) ?? []}
            />
            {/* <Checkbox
              viewStyle={butStyles.secondary}
              shadow
            >
              {t('save_to_my_documents')}
            </Checkbox> */}
            {onExternalFill && (
              <Button
                className={styles.button}
                viewStyle="secondary"
                onClick={() => handleShowSelect('sign')}
                disabled={!permissions?.includes('signature_use')}
                icon={<ReactSVG src="/icons/pencil-sign.svg" wrapper="span" />}
                reversed
                shadow
              >
                {t('send_to_sign')}
              </Button>
            )}
            {onExternalFill && (
              <Button
                className={styles.button}
                viewStyle="secondary"
                onClick={() => handleShowSelect('external')}
                disabled={isFillingValid || !permissions?.includes('externalinfills_create')}
                icon={<ReactSVG src="/icons/document-external.svg" wrapper="span" />}
                reversed
                shadow
              >
                {t('send_to_fiiling')}
              </Button>
            )}
          </ButtonsContainer>
        )
      )}
    </div>
  );
};

export default FillDocument;
