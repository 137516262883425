import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, Switcher } from '@forma/forma-ui-kit';

import styles from './fill-from-card-button.module.css';
import { ReactSVG } from 'react-svg';

interface FillFromCardButtonProps {
  selectedId?: string,
  selectedName?: string,
  onClick: () => void,
  onCheck: (checked: boolean) => void
}

const FillFromCardButton = ({ selectedId, selectedName, onClick, onCheck }: FillFromCardButtonProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Button
        viewStyle="secondary"
        className={classNames(styles.fillButton, selectedId && styles.active)}
        iconClassName={selectedId ? styles.fillButtonSwitch : styles.fillButtonIcon}
        onClick={onClick}
        icon={!selectedId && (
          <ReactSVG src="/icons/user.svg" wrapper="span" />
        )}
        fullWidth={!!selectedId}
        reversed
      >
        {selectedId ? (
          <div className={styles.filledContent}>
            <span>
              {t('uikit:filled_from_card')} {selectedName}
            </span>
            <Switcher
              name="update_counteragent"
              containerClass={styles.fillButtonUpd}
              onChange={e => onCheck(e.target.checked)}
              label={t('uikit:update_card_data')}
              size="small"
            />
          </div>
        ) : (
          t('uikit:fill_from_card')
        )}
      </Button>
    </div>
  );
};

export default FillFromCardButton;
