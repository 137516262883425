import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookie } from 'helpers/cookies';
import { TRIAL_ID } from 'data/constants';

import TariffsList from '../TariffsList';
import PaymentModal from '../PaymentModal';
import TariffDurations from './TariffDurations';
import PromoModal from './PromoModal';

import { IPromocode, ITariff, ITariffDuration, ITariffItem } from 'interfaces/tariffs.interface';
import { IPaymentSubscription, TPaymentMethod } from 'interfaces/billing.interface';
import { ISetTariffParams, useGetTariffPricesQuery } from 'store/tariffs/tariffsApi';

import styles from './tariff-select.module.css';

interface TariffSelectProps {
  current?: ITariff,
  tariffs?: ITariffItem[],
  subscription?: IPaymentSubscription,
  promo: {
    info?: IPromocode,
    onSubmit: (code: string) => Promise<boolean>,
    onCancel: () => void
  },
  initialData?: {
    paymentOpen?: boolean, // todo: check if it needed
    defaultMethod?: TPaymentMethod
  },
  actions: {
    onSetTariff: (params: ISetTariffParams) => Promise<string|null>,
    isSetTariffLoading?: boolean,
    onCreateInvoice: (paymentId: string, billingData: Record<string, string>, source: 'tariffInstance') => Promise<boolean>,
    isCreateInvoiceLoading?: boolean,
    onDownloadInvoice: () => void
  }
}

interface SelectedTariffData {
  id: string,
  name: string,
  members: number,
  attachmentsSize: number
}

const TariffSelect: FC<TariffSelectProps> = ({
  tariffs, current, subscription, promo, actions, initialData
}) => {
  const { t } = useTranslation();
  const [ isShowPromoTariff, setShowPromoTariff ] = useState<boolean>(false);
  const [ selectedDuration, setSelectedDuration ] = useState<number>(0);
  const [ selectedTariff, setSelectedTariff ] = useState<SelectedTariffData|null>(null);

  const selectedTariffData = selectedTariff ? tariffs?.find(({ id }) => id === selectedTariff.id) : null;

  const { data: tariffPrices, isLoading: isPricesLoading } = useGetTariffPricesQuery({
    tariffId: selectedTariff?.id ?? '',
    patternId: selectedTariffData?.patterns.find(({ duration }) => duration === selectedDuration)?.id ?? '',
    addUsersCount: selectedTariffData?.canAddUsers ? selectedTariff?.members : undefined,
    addAttachmentsSize: selectedTariff?.attachmentsSize
  }, { skip: !selectedTariff || !selectedDuration });

  const tariffsCanChoose = useMemo(() => {
    const data: { tariffs: ITariffItem[], patterns: ITariffDuration[] } = { tariffs: [], patterns: [] };

    tariffs?.forEach(tariff => {
      if (tariff.patterns.some(({ canChoose }) => !!canChoose)) data.tariffs.push(tariff);

      tariff.patterns.forEach(pattern => {
        if (pattern.canChoose && !data.patterns.find(({ duration }) => duration === pattern.duration)) data.patterns.push(pattern);
      });
    });

    return data;
  }, [tariffs]);

  useEffect(() => {
    if (!tariffs || !current) return;

    // let promoTariffId: string|null = null;
    // let promoDuration: number|null = null;

    // if (promo?.info?.type === 'tariff' && promo?.info?.patternId) {
    //   tariffs.forEach(({ id, patterns }) => {
    //     const pattern = patterns.find(({ id }) => id === promo?.info?.patternId);
    //     if (pattern) {
    //       promoTariffId = id;
    //       promoDuration = pattern.duration;
    //     }
    //   });
    // }

    // if (promoTariffId && promoDuration) {
    //   duration = promoDuration;

    //   selectedTariff = {
    //     id: promoTariffId,
    //     name: tariffs.find(({ id }) => id === promoTariffId)?.name || '',
    //     members: 0,
    //     // members: current.usersCount - promoTariff.usersLimit;
    //     attachmentsSize: 0
    //   };
    // } else

    const firstTariff = tariffs.find(({ patterns }) => patterns.some(({ canChoose }) => !!canChoose));
    const firstPattern = firstTariff?.patterns.find(({ canChoose }) => !!canChoose);

    setSelectedDuration(firstPattern?.duration ?? 0);

    if (current.tariff.id === TRIAL_ID) {
      const regTariff = getCookie('tariff');
      const cookieTariff: { tariff: string, duration: number, users: number } = regTariff && JSON.parse(decodeURIComponent(regTariff));
      if (cookieTariff?.tariff && cookieTariff.duration && tariffs.find(({ id }) => id === cookieTariff.tariff)) {
        setSelectedDuration(cookieTariff.duration);
        setSelectedTariff({
          id: cookieTariff.tariff,
          name: tariffs.find(({ id }) => id === cookieTariff.tariff)?.name || '',
          members: cookieTariff.users,
          attachmentsSize: 0
        });
      }
    }
  }, [tariffs, current]);

  useEffect(() => {
    if (promo.info?.type === 'tariff') setShowPromoTariff(true);
  }, [promo]);

  const promoTariff = promo.info?.type === 'tariff' ? tariffs?.reduce((acc: ITariffItem[], current: ITariffItem) => {
    const pattern = current.patterns.find(({ id }) => id === promo.info?.patternId);
    if (pattern) return [ ...acc, { ...current, isPromo: true, patterns: [ { ...pattern, isPromo: true } ] } ];
    return acc;
  }, []) : [];

  const handleSetPayment = async () => {
    const tariff = tariffs!.find(tariff => tariff.id === selectedTariff?.id);
    const pattern = tariff?.patterns.find(({ duration }) => duration === selectedDuration);

    if (!tariff || !pattern) return null;

    return actions.onSetTariff({
      tariffId: tariff.id,
      patternId: pattern.id,
      addUsersCount: tariff.canAddUsers ? selectedTariff?.members : undefined,
      addAttachmentsSize: selectedTariff?.attachmentsSize
    });
  };

  const handleCreateInvoice = (paymentId: string, billingData: Record<string, string>) => (
    actions.onCreateInvoice(paymentId, billingData, 'tariffInstance')
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t('select_tariff')}</h1>
        <div className={styles.buttons}>
          <PromoModal {...promo} />
        </div>
      </div>
      <div className={styles.content}>
        <TariffDurations
          patterns={tariffsCanChoose.patterns}
          // patterns={(isShowPromoTariff && promoTariff?.length) ? promoTariff[0].patterns : selectedTariffItem?.patterns}
          selected={selectedDuration}
          onSelect={setSelectedDuration}
          promocode={promo.info}
        />

        <TariffsList
          tariffs={tariffsCanChoose.tariffs}
          // items={(isShowPromoTariff && promoTariff?.length) ? promoTariff : tariffsCanChoose}
          selectedDuration={selectedDuration}
          hasPromoTariff={!!promoTariff?.length}
          isShowPromo={isShowPromoTariff}
          onTogglePromo={setShowPromoTariff}
          onSelectTariff={setSelectedTariff}
          promocode={promo.info}
        />

        {(current && selectedTariff && selectedDuration) && (
          <PaymentModal
            open={!!selectedTariff}
            onClose={() => setSelectedTariff(null)}
            defaultMethod={initialData?.defaultMethod}
            isLoading={isPricesLoading}
            data={{
              name: selectedTariff.name,
              source: 'tariffInstance',
              duration:
                tariffs?.find(({ id }) => id === selectedTariff.id)?.patterns
                  .find(({ duration }) => duration === selectedDuration)?.duration || 0,
              amountToPay: current.amountToPay,
              canChoose: !!tariffPrices?.canChoose,
              subscriptionId: subscription?.id,
              renewPrice: current.renewPrice,
              renewDate: current.dtTo,
            }}
            prices={tariffPrices}
            actions={{
              onSetPayment: handleSetPayment,
              isSetPaymentLoading: actions.isSetTariffLoading,
              onCreateInvoice: handleCreateInvoice,
              isCreateInvoiceLoading: actions.isCreateInvoiceLoading,
              onDownloadInvoice: actions.onDownloadInvoice
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TariffSelect;