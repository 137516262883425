import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';
import SavedPaymentMethods from 'views/tariffs/SavedPaymentMethods';

import {
  useGetAcquiringCardsQuery,
  useGetAcquiringSubscriptionQuery,
  useGetBillingInfoQuery,
} from 'store/billing/billingApi';

import styles from './SavedPaymentMethodsModal.module.css';

interface SavedPaymentMethodsModalProps {
  open?: boolean,
  onClose?: (open: boolean) => void,
}

const SavedPaymentMethodsModal: FC<SavedPaymentMethodsModalProps> = ({
  open, onClose
}) => {
  const { t } = useTranslation();

  const { data: subscription } = useGetAcquiringSubscriptionQuery(undefined, { skip: !open });
  const { data: cards, isLoading: isCardsLoading } = useGetAcquiringCardsQuery(undefined, { skip: !open });
  const { data: billingInfo, isLoading: isBillingLoading } = useGetBillingInfoQuery(undefined, { skip: !open });

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100%"
      maxWidth="985px"
      title={t('subscription.payment_methods')}
    >
      <div className={styles.modal}>
        <SavedPaymentMethods
          subscription={subscription}
          cards={cards}
          billingInfo={billingInfo}
          isLoading={isCardsLoading || isBillingLoading}
        />
      </div>
    </Modal>
  );
};

export default SavedPaymentMethodsModal;
