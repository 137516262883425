import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Modal } from '@forma/forma-ui-kit';
import getValidationSchema from 'data/validationSchema';

import styles from './send-external-fill-modal.module.css';

interface SendExternalFillModalProps {
  open: boolean,
  onClose?: () => void,
  onSubmit: (data: { login: string }) => void,
  onClickCopyLink: () => void,
}

interface FormValues {
  login: string,
}

const fields = [ 'login' ];

const SendExternalFillModal: FC<SendExternalFillModalProps> = ({ open, onClose, onSubmit, onClickCopyLink }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, reset, control, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema(fields))
  });

  const handleFormSubmit: SubmitHandler<FormValues> = (data) => {
    onSubmit(data);
    reset({ login: '' });
    if (onClose) onClose();
  };

  const handleClose = () => {
    reset({ login: '' });
    if (onClose) onClose();
  };

  return (
    <Modal
      size="large"
      open={open}
      onClose={handleClose}
      title={t('send_to_filling_your_counteragent')}
      buttons={[
        {
          className: styles.modalButton,
          viewStyle: 'tertiary',
          children: t('copy_link'),
          onClick: onClickCopyLink
        }
      ]}
    >
      <form id="send_fill_to_form" className={styles.form} onSubmit={handleSubmit(handleFormSubmit)} autoComplete="false">
        <div className={styles.inputs}>
          <Controller
            control={control}
            name="login"
            render={({ field: { value = '', ...rest } }) => (
              <Input
                id="login"
                viewStyle="secondary"
                placeholder={t('email')}
                inputMode="email"
                className={styles.input}
                {...rest}
                {...register}
                value={value}
                meta={{
                  error: !!errors?.login?.message,
                  errorMessage: errors?.login?.message && t('errors.' + errors.login.message),
                }}
              />
            )}
          />
          <Button
            type="submit"
            disabled={!isValid}
            className={styles.button}
          >
            {t('send')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default SendExternalFillModal;
