import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsContainer, Button, Modal } from '@forma/forma-ui-kit';

import { TInvoiceSource } from 'store/billing/billingApi';

import styles from './modal-success.module.css';

interface ModalSuccessProps {
  open: boolean,
  onClose: () => void,
  isInvoice: boolean,
  onClickDownload: () => void,
  source: TInvoiceSource
}

const ModalSuccessDescription = ({ source, isInvoice }: { source: TInvoiceSource, isInvoice: boolean }) => {
  const { t } = useTranslation();

  if (source === 'tariffInstance') {
    if (isInvoice) return <span>{t('subscription.pay_bill_or_lose_access')}</span>;
    return <span>{t('subscription.your_tariff_activated')}</span>;
  }

  if (source === 'signatureRequest') {
    if (isInvoice) return <span>{t('subscription.pay_bill_for_signature')}</span>;
    return <span>{t('subscription.your_signature_activated')}</span>;
  }

  return null;
};

const ModalSuccess: FC<ModalSuccessProps> = ({ open, onClose, isInvoice, onClickDownload, source }) => {
  const { t } = useTranslation();

  return (
    <Modal
      width="100%"
      maxWidth="985px"
      open={open}
      onClose={onClose}
      containerClassName={classNames(styles.modal, 'modal-pay-success')}
      closeOnDocumentClick={false}
    >
      <img className={styles.image} src="/images/success-robot.svg" alt="" width={950} height={297} />
      <div className={styles.title}>
        {isInvoice ? t('subscription.payment_bill_created') : t('subscription.payment_success')}
      </div>
      <div className={styles.description}>
        <ModalSuccessDescription
          source={source}
          isInvoice={isInvoice}
        />
      </div>
      <ButtonsContainer className={styles.buttons}>
        {isInvoice ? (
          <Button
            className={styles.button}
            viewStyle="primary"
            onClick={onClickDownload}
          >
            {t('download_again')}
          </Button>
        ) : (
          <Button
            className={styles.button}
            viewStyle="primary"
            onClick={onClose}
          >
            {t('ok')}
          </Button>
        )}
      </ButtonsContainer>
    </Modal>
  );
};

export default ModalSuccess;
