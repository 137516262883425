import { useState, useEffect, FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { LoadingButton, Modal, Tooltip, Input, LoadingIndicator, ThemeContext, RemoveModal, Button } from '@forma/forma-ui-kit';

import { IUserGroupsItem, IUserPermissionItem } from 'interfaces/users.interface';

import styles from './user-groups-edit.module.css';

interface UserGroupsProps {
  permissions?: IUserPermissionItem[],
  groups?: IUserGroupsItem[],
  onChangeGroup: (id: string, name: string, checked: boolean) => void,
  isGroupLoading: boolean,
  isAddGroupLoading: boolean,
  onAddGroup: (name: string) => void,
  onRemoveGroup: (id: string) => void
}

const UserGroups: FC<UserGroupsProps> = ({
  permissions, groups, onChangeGroup, isGroupLoading, isAddGroupLoading, onAddGroup, onRemoveGroup
}) => {
  const { t } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const [ loadingPermission, setLoadingPermission ] = useState<{ id: string, name: string }|null>(null);
  const [ groupName, setGroupName ] = useState<string>('');

  const isPhone = ['phone', 'tabletS', 'tablet'].includes(viewport);

  useEffect(() => {
    if (groups && !isGroupLoading) setLoadingPermission(null);
  }, [isGroupLoading, groups]);

  const handleCickCreate = () => {
    onAddGroup(groupName);
    setGroupName('');
  };

  return (
    <div className={styles.root}>
      <div className={styles.headButtons}>
        <Modal
          size="small"
          control={<LoadingButton viewStyle="secondary" shadow isLoading={isAddGroupLoading}>{t('add_group')}</LoadingButton>}
          title={t('input_group_name')}
          buttons={[
            {
              children: t('create'),
              onClick: handleCickCreate,
              disabled: groupName === '' || groupName.length < 3
            }
          ]}
        >
          <div className={styles.modalContent}>
            <Input
              type="text"
              name="group_name"
              placeholder={t('input_group_name')}
              onChange={e => setGroupName(e.target.value)}
              value={groupName}
              meta={{
                showClearButton: true
              }}
            />
          </div>
        </Modal>
      </div>

      <div className={styles.table}>
        {!isPhone && (
          <div className={classNames(styles.row, styles.header)}>
            <div className={classNames(styles.column, styles.columnName)}>{t('group_name')}</div>
            {permissions ? (
              permissions.map(({ name }, index) => (
                <div className={styles.column} key={'head' + index}>
                  {name ? (
                    <Tooltip
                      className="groups-tooltip"
                      control={<span>{t(`permissions.${name}`)}</span>}
                      position={['bottom center', 'bottom left', 'bottom right']}
                    >
                      {t(`permissions.${name}_descr`)}
                    </Tooltip>
                  ) : (
                    <div className={classNames(styles.rowNameLoader, 'skeleton-loader')} />
                  )}
                </div>
              ))
            ) : (
              [...Array(3)].map((item, index) => (
                <div className={styles.column} key={'head' + index}>
                  <div className={classNames(styles.rowNameLoader, 'skeleton-loader')} />
                </div>
              ))
            )}
            <div style={{ width: '24px', flexShrink: 0 }} />
          </div>
        )}

        {groups ? (
          groups.map(({ id, name, permissions: groupPermissions }, index) => (
            <div className={styles.row} key={'row' + id}>
              <div className={classNames(styles.column, styles.columnName)}>
                {name}

                {isPhone && (
                  <RemoveModal
                    control={
                      <Button
                        className={styles.remove}
                        viewStyle="textLight"
                        icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                      />
                    }
                    onRemove={() => onRemoveGroup(id)}
                    title={t('do_yo_want_delete_group')}
                  />
                )}
              </div>

              {permissions ? (
                permissions.map(({ name, desc }) => {
                  const isLoading = loadingPermission?.id === id && loadingPermission?.name === name;
                  const checked = !!groupPermissions.find(pName => pName === name);
                  const handleChangeGroup = (id: string, name: string, checked: boolean) => {
                    setLoadingPermission({ id, name });
                    onChangeGroup(id, name, checked);
                  };

                  return (
                    <div className={styles.column} key={id + name}>
                      {isPhone &&
                        <div className={styles.columnDescr}>{t(`permissions.${name}`)}</div>
                      }
                      <label
                        className={classNames(styles.checkbox)}
                        tabIndex={0}
                      >
                        {(checked && !isLoading) &&
                          <ReactSVG className={styles.icon} src="/icons/tick.svg" wrapper="span" />
                        }
                        {isLoading &&
                          <LoadingIndicator />
                        }
                        <input
                          type="checkbox"
                          className={styles.checkboxInput}
                          checked={checked}
                          onChange={e => handleChangeGroup(id, name, e.target.checked)}
                        />
                      </label>
                    </div>
                  );
                })
              ) : (
                [...Array(3)].map((item, perIndex) => (
                  <div className={styles.column} key={'col' + id + perIndex}>
                    <div className={classNames(styles.checkbox, 'skeleton-loader')} key={index} />
                  </div>
                ))
              )}

              {!isPhone && (
                <RemoveModal
                  control={
                    <Button
                      title={t('delete')}
                      className={styles.remove}
                      viewStyle="textLight"
                      icon={<ReactSVG src="/icons/trash.svg" wrapper="span" />}
                    />
                  }
                  onRemove={() => onRemoveGroup(id)}
                  title={t('do_yo_want_delete_group')}
                />
              )}
            </div>
          ))
        ) : (
          [...Array(3)].map((item, index) => (
            <div className={styles.row} key={'row' + index}>
              <div className={styles.column}>
                <div className={classNames(styles.rowNameLoader, 'skeleton-loader')} />
              </div>
              {[...Array(8)].map((item, permIndex) => (
                <div className={styles.column} key={'col' + index + permIndex}>
                  {isPhone && <div className={classNames(styles.rowNameLoader, 'skeleton-loader')} />}
                  <div className={classNames(styles.checkbox, 'skeleton-loader')} />
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UserGroups;