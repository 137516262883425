import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer } from '@forma/forma-ui-kit';

import styles from './request-ready.module.css';

interface RequestReadyProps {
  onClick: () => void
}

const RequestReady: FC<RequestReadyProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <img className={styles.image} src="/icons/file_types/forma.svg" alt="" />
      <div className={styles.title}>{t('guide.your_templates_ready')}</div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          size="extraSmall"
          className={styles.button}
          onClick={onClick}
        >
          {t('guide.go_to_templates')}
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default RequestReady;
