import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Modal, SearchInput } from '@forma/forma-ui-kit';
import SelectSwitcher from 'views/FilesEditor/SelectSwitcher';

import { IProductPropertiesItem } from 'interfaces/products.interface';

import styles from './select-properties-modal.module.css';

interface SelectPropertiesModalProps {
  open: boolean,
  onClose: (open: false) => void,
  userVariables?: IProductPropertiesItem[],
  selected: string[],
  onChangeSelected: (selected: string[]) => void,
}

const SelectPropertiesModal = ({
  open, onClose, userVariables, selected, onChangeSelected
}: SelectPropertiesModalProps) => {
  const { t } = useTranslation();
  const [ searchValue, setSearchValue ] = useState<string>('');

  useEffect(() => {
    setSearchValue('');
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('add_data')}
      size="medium"
    >
      <SearchInput placeholder={t('enter_variable_name')} onSearch={setSearchValue} />
      <div className={styles.dropdownSwitches}>
        {userVariables?.filter(({ name }) => name.toLowerCase().includes(searchValue.toLowerCase()))
          .map(({ id, name, type }) => (
            <SelectSwitcher
              label={name}
              checked={selected.includes(id)}
              onChange={(checked) => onChangeSelected((checked ? [ ...selected, id ] : selected.filter(item => item !== id)))}
              icon={checked => checked &&
                <ReactSVG src="/icons/checked.svg" wrapper="span" className={styles.checkedIcon} />
              }
              key={id}
            />
          ))
        }
      </div>
    </Modal>
  );
};

export default SelectPropertiesModal;
