import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AdminLayout } from 'components/Layouts';
import PageTitle from 'components/PageTitle';
import { mock } from '@forma/forma-ui-kit';
import ProductsServicesEdit from 'views/products/ProductsServicesEdit';

import { useAppDispatch } from 'store/hooks';
import {
  useAddProductMutation,
  useGetProductByIdQuery,
  useGetProductsCategoriesQuery,
  useGetProductsPropertiesQuery,
  useSaveProductMutation
} from 'store/products/productsApi';
import { addNotification } from 'store/notifications/notificationsSlice';
import { IProductsItemCreate, IProductsItemUpdate } from 'interfaces/products.interface';

const ProductsEdit = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: product, isLoading } = useGetProductByIdQuery(id ?? '', { skip: !id });
  const { data: categories } = useGetProductsCategoriesQuery();
  const { data: properties } = useGetProductsPropertiesQuery();
  const [ addProduct, { isLoading: isAddLoading } ] = useAddProductMutation();
  const [ saveProduct, { isLoading: isSaveLoading } ] = useSaveProductMutation();

  const handleCreate = async (data: IProductsItemCreate) => {
    const res = await addProduct(data);
    if ('data' in res) {
      navigate('/products');
      dispatch(addNotification({ content: t('product_service_was_added'), type: 'SUCCESS' }));
    }
  };

  const handleUpdate = async (data: IProductsItemUpdate) => {
    const res = await saveProduct(data);
    if ('data' in res) {
      navigate('/products');
      dispatch(addNotification({ content: t('data_successfully_changed'), type: 'SUCCESS' }));
    }
  };

  let title = '';
  if (!id) title = t('add_product_service');
  else if (product) title = product.name;

  return (
    <AdminLayout
      title={title}
      breadcrumbs={{ items: [ { name: t('catalog_products_and_services'), to: '/products', as: Link }, { name: title } ] }}
    >
      <PageTitle>{t('site_name') + ' – ' + title}</PageTitle>
      <ProductsServicesEdit
        product={product}
        availableVariables={mock.productTattrs}
        userVariables={properties}
        categories={categories}
        isLoading={isLoading}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        isSaveLoading={isAddLoading || isSaveLoading}
      />
    </AdminLayout>
  );
};

export default ProductsEdit;
