import { memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from '@forma/forma-ui-kit';
import Logo from 'components/Logo';
import { menu } from 'data/menu';
import { TRIAL_ID } from 'data/constants';

import { useAppSelector } from 'store/hooks';
// import { setMenuOpen, selectMenuOpen } from 'store/common/commonSlice';
import { useGetUsersListQuery, useSetOnboardingMutation } from 'store/user/userApi';
import { selectOnboarding } from 'store/user/userSlice';
import { useGetTcRequestsQuery } from 'store/tcrequests/tcrequestsApi';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';

import MenuItem from './MenuItem';

import styles from './side-menu.module.css';

export type TMenuItem = {
  id: string,
  icon: string,
  lang: string,
  href: string,
  target?: string,
  routes?: string[],
  disabled?: boolean,
  permissions?: string,
};

export type TMenuItemParent = {
  items?: TMenuItem[]
} & TMenuItem;

const SideMenu = memo(() => {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  const { pathname, hash } = useLocation();
  // const { viewport } = useContext(ThemeContext);
  // const isMenuOpen = useAppSelector(selectMenuOpen);
  const onboarding = useAppSelector(selectOnboarding);

  const { data: tcRequests } = useGetTcRequestsQuery();
  const { data: users } = useGetUsersListQuery({});
  const { data: currentTariff } = useGetCurrentTariffQuery();
  const [ setOnboarding, { isLoading: isSetOnboardingLoading } ] = useSetOnboardingMutation();

  const [ isShowAlert, setShowAlert ] = useState(false);

  // const handleClickMenuButton = () => {
  //   dispatch(setMenuOpen(!isMenuOpen));
  // };

  // const handleClickMenuItem = () => {
  //   if (['phone', 'tablet', 'tabletS'].includes(viewport)) dispatch(setMenuOpen(false));
  // };

  const disableMenu = !!(onboarding && tcRequests && !onboarding.templates_sent && !tcRequests.length);

  const handleClickListItem = () => {
    if (disableMenu) setShowAlert(true);
  };

  const handleSkipGuide = () => {
    setOnboarding({ templates_sent: true }).then(() => {
      if (currentTariff && (currentTariff?.tariff.id === TRIAL_ID || !currentTariff.isPaid)) {
        localStorage.setItem('setting_templates_notification', 'true');
      }
      window.location.href = '/templates';
    });
  };

  return (
    <>
      <div className={styles.root}>
        <Logo className={styles.logo} link={disableMenu ? undefined : '/'} />

        <ul className={styles.list}>
          {(onboarding && !onboarding?.templates_sent) && (
            <MenuItem
              id="homepage"
              currentPathname={pathname + hash}
              // onClick={handleClickMenuItem}
              className={styles.homepage}
              lang="homepage"
              href="/guide"
              icon="/icons/menu/homepage.svg"
            />
          )}
          {menu.map((item: TMenuItemParent) => (
            <MenuItem
              {...item}
              currentPathname={pathname + hash}
              // onClick={handleClickMenuItem}
              onClickItem={handleClickListItem}
              className={styles[item.id]}
              disabled={disableMenu || item.disabled}
              key={item.id}
            />
          ))}
          <MenuItem
            id="subscribe"
            currentPathname={pathname + hash}
            // onClick={handleClickMenuItem}
            className={styles.subscribe}
            lang="payment_and_tariffs"
            href="/select-tariff"
            icon="/icons/menu/payment.svg"
          />
          {(users && users.items.length > 1) ? (
            <MenuItem
              id="users"
              icon="/icons/menu/users.svg"
              lang="users"
              href="/users"
              currentPathname={pathname + hash}
              // onClick={handleClickMenuItem}
            />
          ) : (
            <MenuItem
              id="invite_user"
              icon="/icons/menu/add-user.svg"
              lang="invite_user"
              href="/invite"
              currentPathname={pathname + hash}
              // onClick={handleClickMenuItem}
            />
          )}
        </ul>
        <Modal
          open={isShowAlert}
          onClose={setShowAlert}
          title={t('guide.need_setting_templates')}
          size="small"
          buttons={[
            {
              children: t('setting_templates'),
            },
            {
              viewStyle: 'tertiary',
              children: t('setting_all_myself'),
              onClick: handleSkipGuide,
              isLoading: isSetOnboardingLoading
            }
          ]}
        >
          <div className={styles.modal}>
            {t('guide.need_setting_templates_description')}
          </div>
        </Modal>
      </div>
    </>
  );
});

export default SideMenu;
