import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FillFolder, Modal } from '@forma/forma-ui-kit';
import { ORG_INN_LENGTH } from 'data/constants';
import { organizationTattrs } from 'data/mock';
import webView from 'helpers/webview';

import { IExternalFillOwner } from 'interfaces/externalfill.interface';
import { useCreateExternalFillOwnerMutation, useUpdateExternalFillOwnerMutation } from 'store/externalfill/externalfillApi';
import { ICreateInvoiceData, useCreateInvoiceMutation } from 'store/billing/billingApi';

import styles from './edit-organization-modal.module.css';

interface EditOrganizationModalProps {
  open: boolean,
  onClose: () => void,
  data?: IExternalFillOwner,
  onSubmit: (ownerId: string) => void
}

const EditOrganizationModal: FC<EditOrganizationModalProps> = ({ open, onClose, data, onSubmit }) => {
  const { t } = useTranslation();
  const [ createExternalFillOwner, { isLoading: isAddLoading } ] = useCreateExternalFillOwnerMutation();
  const [ updateExternalFillOwner, { isLoading: isUpdateLoading } ] = useUpdateExternalFillOwnerMutation();
  const [ createInvoice, { isLoading: isInvoiceLoading } ] = useCreateInvoiceMutation();

  const { register, control, setValue, reset, watch, handleSubmit, formState: { errors, isValid } } = useForm<{ [key: string]: string }>({
    mode: 'onChange',
    defaultValues: { companyname: data?.name ?? '', address: data?.address ?? '', inn: data?.inn ?? '', kpp: data?.kpp ?? '' }
  });

  const inn = watch('inn');

  useEffect(() => {
    reset({ companyname: data?.name ?? '', address: data?.address ?? '', inn: data?.inn ?? '', kpp: data?.kpp ?? '' });
    // eslint-disable-next-line
  }, [data]);

  const downloadInvoice = async (owner: IExternalFillOwner) => {
    const sendData: ICreateInvoiceData = {
      id: owner.id,
      source: 'externalInfillEntity',
      details: {
        companyname: owner.name,
        inn: owner.inn,
        kpp: owner.kpp ?? '',
        ogrn: '',
        address: owner.address
      }
    };

    if (window?.navigator?.userAgent === 'forma-mobile') {
      webView.createInvoice(`${t('subscription.bill_document_name')}.pdf`, sendData);
      return true;
    } else {
      const result = await createInvoice(sendData).unwrap().then(url => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${t('subscription.bill_document_name')}.pdf`;
        document.body.appendChild(a);
        a.click();

        return true;
      }).catch(() => false);

      return result;
    }
  };

  const handleSubmitForm = async (values: { [key: string]: string }) => {
    const ownerData = {
      name: values.companyname,
      address: values.address,
      inn: values.inn,
      kpp: values.inn.length === ORG_INN_LENGTH ? values.kpp : undefined
    };

    const res = data
      ? await updateExternalFillOwner({ id: data.id, ...ownerData })
      : await createExternalFillOwner(ownerData);

    if (!('data' in res)) return;

    const resDownload = await downloadInvoice(res.data);

    if (!resDownload) return;
    onSubmit(res.data.id);
    handleClose();
  };

  const handleClose = () => {
    reset({ companyname: '', address: '', inn: '', kpp: '' });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={
        <div>
          <div>{t('fill_info_organization_collect_privacy')}</div>
          <div className={styles.subtitle}>
            <Trans i18nKey="fill_info_organization_collect_privacy_description" />
          </div>
        </div>
      }
      size="large"
      buttons={[
        {
          type: 'submit',
          form: 'contragent_form',
          children: t('issue_invoice_for_company_verification'),
          className: styles.save,
          isLoading: isAddLoading || isUpdateLoading || isInvoiceLoading,
          disabled: !isValid || !inn,
          closing: false
        }
      ]}
    >
      <form id="contragent_form" className={styles.form} onSubmit={handleSubmit(handleSubmitForm)}>
        <FillFolder
          tattrs={organizationTattrs.map(item => (inn?.length !== ORG_INN_LENGTH && item.id === 'kpp') ? { ...item, required: false } : item)}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          itemsClassName={styles.modalField}
        />
      </form>
    </Modal>
  );
};

export default EditOrganizationModal;
