import { ITemplateBlocksTree } from '@forma/forma-ui-kit';

interface CarticleCounts {
  id: string,
  variants: {
    id: string,
    tattrs: string[],
    tables: string[]
  }[],
  parents: string[],
  countBody: number,
  countVariative: number
}

const getLegacyCountsFromTree = (tree: ITemplateBlocksTree): CarticleCounts[] => {
  const carticles: { [key: string]: CarticleCounts } = {};

  const collect = (tree: ITemplateBlocksTree, variantId?: string) => {
    tree.carticles.forEach(carticle => {
      const mappedCarticle = carticles[carticle.id];

      if (mappedCarticle) carticles[carticle.id] = {
        ...mappedCarticle,
        variants: mappedCarticle.variants.map((mappedVariant) => {
          const variant = carticle.variants.find(({ id }) => mappedVariant.id === id);
          if (!variant) return mappedVariant;
          return {
            id: mappedVariant.id,
            tattrs: [ ...mappedVariant.tattrs, ...variant.childs.tattrs ],
            tables: [ ...mappedVariant.tables, ...variant.childs.tables ]
          };
        }),
        parents: variantId ? [ ...mappedCarticle.parents, variantId ] : mappedCarticle.parents,
        countBody: mappedCarticle.countBody + 1,
        countVariative: variantId ? mappedCarticle.countVariative + 1 : mappedCarticle.countVariative
      };
      else carticles[carticle.id] = {
        id: carticle.id,
        variants: carticle.variants.map(({ id, childs }) => ({ id, tattrs: childs.tattrs, tables: childs.tables })),
        parents: variantId ? [ variantId ] : [],
        countBody: 1,
        countVariative: variantId ? 1 : 0
      };
    });
    for (const carticle of tree.carticles) {
      for (const variant of carticle.variants) {
        collect(variant.childs, variant.id);
      }
    }
  };

  collect(tree);

  return Object.values(carticles);
};

const legacyCountTattrsById = (tree: ITemplateBlocksTree, id: string, skipFirstLevel?: boolean): number => {
  let tattrsCount = 0;

  if (!skipFirstLevel) {
    tattrsCount += tree.tattrs.filter(tattrId => tattrId === id).length;
  }

  for (const carticle of tree.carticles) {
    for (const variant of carticle.variants) {
      tattrsCount += legacyCountTattrsById(variant.childs, id);
    }
  }

  return tattrsCount;
};

export { getLegacyCountsFromTree, legacyCountTattrsById };
