import { FC, ReactElement, ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FileDropzone, Modal, phonemasks } from '@forma/forma-ui-kit';
import formatPhoneNumber from 'helpers/formatPhoneNumber';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addNotification } from 'store/notifications/notificationsSlice';
import { useSendTcRequestMutation } from 'store/tcrequests/tcrequestsApi';
import { selectUserData } from 'store/user/userSlice';

import styles from './fill-request-modal.module.css';

interface FillRequestModalProps {
  open?: boolean,
  onClose?: (open: false) => void,
  onSend?: () => void,
  control?: ReactElement,
  title?: ReactNode,
  showClose?: boolean
}

const FillRequestModal: FC<FillRequestModalProps> = ({ open, onClose, onSend, control, showClose = true }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const user = useAppSelector(selectUserData);
  const [ files, setFiles ] = useState<File[]>([]);
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
  const [ sendRequest, { isLoading: isSendLoading } ] = useSendTcRequestMutation();

  const defaultMaskCode = (i18n.resolvedLanguage && i18n.resolvedLanguage !== 'en') ? i18n.resolvedLanguage.toUpperCase() : 'US';
  const phoneMask = phonemasks.find(({ iso }) => iso === defaultMaskCode);

  const handleClickUpload = async () => {
    for await (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      await sendRequest(formData)
        .catch(() => {
          dispatch(addNotification({ content: t('errors.file_not_send', { name: file.name }), type: 'ERROR' }));
        });
    }

    setIsSuccess(true);
    setFiles([]);
    if (onSend) onSend();
  };

  const handleModalClose = () => {
    setIsSuccess(false);
    if (onClose) onClose(false);
  };

  return (
    <>
      <Modal
        open={open}
        control={control}
        maxWidth="730px"
        width="100%"
        onClose={handleModalClose}
        title={isSuccess ? null : t('templates_set_up')}
        buttonsClassName={styles.buttons}
        showClose={showClose}
        closeOnDocumentClick={showClose}
        showCloseMobile={showClose}
        buttons={
          isSuccess ? [{
            className: styles.successButton,
            viewStyle: 'primary',
            children: t('fine')
          }] : [{
            className: styles.uploadButton,
            viewStyle: 'primary',
            children: t('move_templates_in_forma'),
            onClick: handleClickUpload,
            isLoading: isSendLoading,
            disabled: !files.length,
            closing: false,
          // }, {
          //   className: styles.cancelButton,
          //   viewStyle: 'textLight',
          //   children: t('setting_all_myself'),
          }]
        }
      >
        <input type="hidden" autoFocus />
        {isSuccess ? (
          <div className={styles.successModal}>
            <img className={styles.successImage} src="/images/success-robot-2.svg" width={392} height={179} alt="" />
            <div className={styles.successTitle}>
              {t('templates_set_up_sended')}
            </div>
            <div className={styles.successDescr}>
              <Trans
                i18nKey="templates_set_up_sended_description"
                components={{
                  phone: <span className="accent-text">{(user && phoneMask) && formatPhoneNumber(String(user.phone), phoneMask.code+phoneMask.mask)}</span>
                }}
              />
            </div>
          </div>
        ) : (
          <FileDropzone
            className={styles.dropzone}
            contentClassName={styles.dropzoneContent}
            title={
              <Trans
                i18nKey="guide.dropzone_title"
                components={{
                  icon: <img className={styles.dropzoneIcon} src="/icons/stars-green.svg" width={24} height={24} alt="" />
                }}
              />
            }
            accept={{
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
              'application/msword': ['.doc'],
              'application/rtf': ['.rtf'],
              'application/vnd.apple.pages': ['.pages'],
              'application/pdf': ['.pdf']
            }}
            maxFiles={20}
            onChange={setFiles}
          />
        )}
      </Modal>
    </>
  );
};

export default FillRequestModal;
