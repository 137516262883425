import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import { AdminLayout } from 'components/Layouts';
import GuideView from 'views/Guide';
import { TRIAL_ID } from 'data/constants';

import { useAppDispatch } from 'store/hooks';
import { useSetOnboardingMutation } from 'store/user/userApi';
import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { useGetTcRequestsQuery, useSendTcRequestMutation } from 'store/tcrequests/tcrequestsApi';
import { addNotification } from 'store/notifications/notificationsSlice';

import styles from './Guide.module.css';

const Guide = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ readyDate, setReadyDate ] = useState<Date|null>(null);

  const { data: currentTariff } = useGetCurrentTariffQuery();
  const [ setOnboarding, { isLoading: isSetOnboardingLoading } ] = useSetOnboardingMutation();
  const [ sendTcRequest, { isLoading: isSendLoading } ] = useSendTcRequestMutation();
  const { data: tcRequests } = useGetTcRequestsQuery();

  const saveReadyDate = (hours: number) => {
    const date = new Date();
    date.setHours(date.getHours()+hours);
    localStorage.setItem('tc_request_ready', date.toISOString());
    setReadyDate(date);
  };

  useEffect(() => {
    const readyAt = localStorage.getItem('tc_request_ready');
    if (readyAt) setReadyDate(new Date(readyAt));
  }, [tcRequests]);

  const handleSkip = () => {
    setOnboarding({ templates_sent: true }).then(() => {
      if (currentTariff && (currentTariff?.tariff.id === TRIAL_ID || !currentTariff.isPaid)) {
        localStorage.setItem('setting_templates_notification', 'true');
      }
      window.location.href = '/templates';
    });
  };

  const handleEnd = () => {
    setOnboarding({ templates_sent: true }).then(() => {
      window.location.href = '/templates';
    });
  };

  const handleSendFiles = (files: File[]) => {
    Promise.all(files.map(async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      await sendTcRequest(formData)
        .then(() => {
          saveReadyDate(3);
        })
        .catch(() => {
          dispatch(addNotification({ content: t('errors.file_not_send', { name: file.name }), type: 'ERROR' }));
        });
    }));
  };

  return (
    <AdminLayout
      title={t('service_guide')}
      className={styles.container}
    >
      <PageTitle>{t('site_name') + ' – ' + t('service_guide')}</PageTitle>
      <GuideView
        requests={tcRequests}
        onSkip={handleSkip}
        isSkipLoading={isSetOnboardingLoading}
        onSendFiles={handleSendFiles}
        isSendLoading={isSendLoading}
        readyDate={readyDate}
        onEnd={handleEnd}
      />
    </AdminLayout>
  );
};

export default Guide;
