import { Trans, useTranslation } from 'react-i18next';
import { addHours, differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@forma/forma-ui-kit';
import { getCookie, setCookie } from 'helpers/cookies';
import formatPrice from 'helpers/formatPrice';
import { TRIAL_ID, DISCOUNT_DURATION, DISCOUNT_PROMOCODE, DISCOUNT_PERCENT, DISCOUNT_TARIFF } from 'data/constants';
import { currencies } from 'data/mock';

import { TPaymentMethod } from 'interfaces/billing.interface';
import { useAppSelector } from 'store/hooks';
import { selectUserData } from 'store/user/userSlice';
import { useGetCurrentTariffQuery, useGetTariffsQuery } from 'store/tariffs/tariffsApi';

import styles from './discount-modal.module.css';

const SHOW_INTERVAL_HOURS = 2;

const DiscountModal = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUserData);
  const navigate = useNavigate();

  const currency = currencies.ru;

  const now = new Date();
  const created = user && new Date(user.workspace.createdAt);
  const diff = created ? differenceInDays(now, created) : 0;

  const isPayShowed = getCookie('isPayShowed');

  const { data: currentTariff } = useGetCurrentTariffQuery(undefined, { skip: diff < 1 || !!isPayShowed });
  const { data: tariffs } = useGetTariffsQuery();

  const discountTariff = tariffs?.find(({ id }) => id === DISCOUNT_TARIFF);
  const duration = discountTariff?.patterns.find(({ duration }) => duration === DISCOUNT_DURATION);
  const durationMonths = duration ? duration.duration / 30 : 0;
  const durationBasePrice = duration ? duration.basePrice / durationMonths : 0;

  const isShow = (diff >= 1 && currentTariff?.tariff.id === TRIAL_ID && currentTariff?.isActive && !isPayShowed);

  const handleClickPay = (type: TPaymentMethod) => {
    const date = new Date();
    localStorage.setItem('promocode', DISCOUNT_PROMOCODE);
    setCookie(
      'tariff',
      JSON.stringify({ tariff: DISCOUNT_TARIFF, duration: DISCOUNT_DURATION }),
      {
        path: '/',
        expires: new Date(date.setDate(date.getDate() + 7)),
        domain: window.location.hostname
      }
    );
    navigate('/select-tariff', { state: { paymentOpen: true, paymentMethod: type } });
  };

  return (
    <Modal
      open={isShow}
      width="100%"
      maxWidth="730px"
      onClose={() => setCookie('isPayShowed', 'true', { expires: addHours(new Date(), SHOW_INTERVAL_HOURS) })}
      title={
        <Trans
          i18nKey="subscription.subscribe_with_discount"
          components={{
            accent: <span className="accent-text" />
          }}
        />
      }
      buttonsClassName={styles.buttons}
      buttons={[
        {
          viewStyle: 'primary',
          children: t('subscription.pay_by_bill'),
          onClick: () => handleClickPay('invoice')
        },
        {
          viewStyle: 'tertiary',
          children: t('subscription.pay_by_card'),
          onClick: () => handleClickPay('card')
        }
      ]}
    >
      <div className={styles.content}>
        {t('subscription.all_tariff_functions_for', { tariff: discountTariff?.name })}
        <div className={styles.prices}>
          <div className={styles.price}>{formatPrice(durationBasePrice/100*(100-DISCOUNT_PERCENT), currency)} / {t('month_short')}</div>
          <div className={styles.oldPrice}>{formatPrice(durationBasePrice, currency)} / {t('month_short')}</div>
        </div>
        = {formatPrice(durationBasePrice/100*(100-DISCOUNT_PERCENT)*durationMonths, currency)} {t('subscription.for_half_year')}
      </div>
      <div className={styles.advantages}>
        {[...Array(4)].map((_, index) => (
          <div key={index}>{t(`subscription.advantages.${index+1}`)}</div>
        ))}
      </div>
    </Modal>
  );
};

export default DiscountModal;
