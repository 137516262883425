import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Modal, LoadingIndicator } from '@forma/forma-ui-kit';
import paymentService from 'helpers/paymentService';
import formatPrice from 'helpers/formatPrice';
import { currencies } from 'data/mock';

import { baseApi } from 'store/store';
import { useAppDispatch } from 'store/hooks';
import { TInvoiceSource, useGetAcquiringDataMutation } from 'store/billing/billingApi';

import styles from './modal-pay.module.css';

interface ModalPayProps {
  open: boolean,
  onClose: () => void,
  id: string,
  source: TInvoiceSource,
  prices: {
    amountToPay: number,
    renewPrice?: number,
    renewDate?: string
  }
  onSuccess: () => void,
  onError: () => void,
}

const ModalPay: FC<ModalPayProps> = ({
  open, onClose, id, source, prices, onSuccess, onError
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const currency = currencies.ru;
  const [ isWigdetLoading, setWidgetLoading ] = useState(true);

  const [ getAcquiringData, { isLoading: isGetAcquiringLoading } ] = useGetAcquiringDataMutation();

  useEffect(() => {
    // if (!open) return () => paymentService.destroy();
    if (!open) return;

    const getData = async () => {
      const paymentData = await getAcquiringData({ id, source });
      if ('data' in paymentData) {
        paymentService.pay(
          {
            ...paymentData.data,
            language: currency.code,
          },
          () => setWidgetLoading(false),
          () => {
            onSuccess();
            dispatch(baseApi.util.invalidateTags(['TariffsHistory', 'CurrentTariff', 'Subscription', 'TariffPromocode']));
          },
          () => onError()
        );
      }
    };

    getData();

    // return () => paymentService.destroy();

    // eslint-disable-next-line
  }, [open, id, source]);

  return (
    <Modal
      width="100%"
      maxWidth="985px"
      open={open}
      onClose={onClose}
      title={
        <>
          {t('subscription.now_you_pay', { price: formatPrice(prices.amountToPay, currency) })}
          {prices.renewPrice && prices.renewDate && (
            <div className={styles.description}>
              {t('subscription.now_you_pay_description', {
                price: formatPrice(prices.renewPrice, currency),
                date: format(new Date(prices.renewDate), 'dd MMMM yyyy', { locale: ru })
              })}
            </div>
          )}
        </>
      }
      closeOnDocumentClick={false}
    >
      <div className={styles.modal} id="modal_pay_content">
        {(isGetAcquiringLoading || isWigdetLoading) && (
          <LoadingIndicator color="var(--primary-color)" />
        )}
      </div>
    </Modal>
  );
};

export default ModalPay;
