import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FileWithPath } from 'react-dropzone';
import classNames from 'classnames';
import { Modal, Input, FileDropzone } from '@forma/forma-ui-kit';
import SignatureItem from './SigantureItem';
import { useAddFacsimileMutation, useGetFacsimilesQuery, useRemoveFacsimileMutation } from 'store/facsimiles/facsimilesApi';

import styles from './signature-add.module.css';

interface SignatureAddProps {
  open: boolean,
  onClose: () => void,
  onAdd: (url: string) => void
}

const NEW_FILE_ID = 'new';

const SignatureAdd: FC<SignatureAddProps> = ({ open, onClose, onAdd }) => {
  const { t } = useTranslation();
  const [ name, setName ] = useState<string>('');
  const [ file, setFile ] = useState<FileWithPath|null>(null);
  const [ selected, setSelected ] = useState<string|null>(null);

  const { data: signatures } = useGetFacsimilesQuery(undefined, { skip: !open });
  const [ removeSignature ] = useRemoveFacsimileMutation();
  const [ addSignature ] = useAddFacsimileMutation();

  const handleAddFile = (files: FileWithPath[]) => {
    setFile(files[0]);
    setSelected(NEW_FILE_ID);
  };

  const handleClickUpload = async () => {
    if (selected === NEW_FILE_ID && file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name);
      const res = await addSignature(formData);
      if ('data' in res) onAdd(res.data.filePath);
    } else {
      onAdd(signatures?.find(({ id }) => id === selected)?.filePath ?? '');
    }

    setSelected(null);
    setFile(null);
  };

  return (
    <Modal
      size="medium"
      open={open}
      onClose={onClose}
      title={t('facsimile')}
      buttons={[{
        children: t('add'),
        className: styles.modalButton,
        onClick: handleClickUpload,
        disabled: !selected || (selected === NEW_FILE_ID && (!file || name.length < 3))
      }]}
    >
      <div className={styles.modal}>
        <Input
          name="facsimile_name"
          placeholder={t('enter_document_name')}
          value={name}
          onChange={e => setName(e.target.value)}
          meta={{
            containerClass: styles.input,
            showClearButton: true
          }}
        />
        {file ? (
          <div className={styles.preview}>
            <div className={styles.previewItem}>
              <SignatureItem
                id="new"
                filePath={URL.createObjectURL(file)}
                isSelected={selected === NEW_FILE_ID}
                onSelect={setSelected}
                onRemove={() => { setSelected(null); setFile(null); }}
              />
            </div>
          </div>
        ) : (
          <FileDropzone
            placeholder={<Trans i18nKey="facsimile_dropzone_placeholder" />}
            onChange={handleAddFile}
            className={styles.dropzone}
            accept={{ 'image/jpeg': [], 'image/png': [], 'image/bmp': [], 'image/gif': [], 'image/tiff': [], 'image/webp': [] }}
          />
        )}
        {signatures && (
          <div className={styles.uploaded}>
            <div className={styles.uploadedTitle}>{t('or_select_from_uploaded')}</div>
            <div className={classNames(styles.uploadedItems, 'styled-scrollbar')}>
              {signatures?.map(({ id, name, filePath }) => (
                <div className={styles.uploadedItem} key={id}>
                  <SignatureItem
                    id={id}
                    name={name}
                    filePath={filePath}
                    isSelected={selected === id}
                    onSelect={setSelected}
                    onRemove={removeSignature}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SignatureAdd;
