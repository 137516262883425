import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './profile-card.module.css';

interface ProfileCardProps {
  title?: ReactNode,
  children: ReactNode,
  className?: string,
}

const ProfileCard = ({ title, children, className }: ProfileCardProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      {title && (
        <div className={styles.title}>{title}</div>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ProfileCard;
