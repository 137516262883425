import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonsContainer, Button, Modal } from '@forma/forma-ui-kit';

import styles from './modal-error.module.css';

interface ModalErrorProps {
  open: boolean,
  onClose: () => void,
  onClickRetry: () => void
}

const ModalError: FC<ModalErrorProps> = ({ open, onClose, onClickRetry }) => {
  const { t } = useTranslation();

  return (
    <Modal
      width="100%"
      maxWidth="985px"
      open={open}
      onClose={onClose}
      containerClassName={classNames(styles.modal, 'modal-pay-error')}
      closeOnDocumentClick={false}
    >
      <img className={styles.image} src="/images/error-robot.svg" alt="" width={950} height={297} />
      <div className={styles.title}>
        {t('subscription.payment_error')}
      </div>
      <div className={styles.description}>
        <Trans
          i18nKey={t('subscription.payment_error_description')}
        />
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          className={styles.button}
          viewStyle="primary"
          onClick={onClickRetry}
        >
          {t('try_again')}
        </Button>
      </ButtonsContainer>
    </Modal>
  );
};

export default ModalError;
