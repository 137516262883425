import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';
import { UseFormReset, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import update from 'immutability-helper';
import { FillForm, FillVariations } from '@forma/forma-ui-kit';
import ConteragentsSearch from 'views/contragents/ConteragentsSearch';
import FillFromCardButton from './FillFromCardButton';
import FillSideTable from './FillSideTable';

import {
  ISideEditData,
  ITemplateSideGroup,
  ITemplateVariation,
  ITemplateTable,
  ISidesListEditData,
  ISideSendingData,
  ISidesListSendingData
} from 'interfaces/templates.interface';

import { useLazyGetCounteragentByIdQuery } from 'store/counteragents/counteragentsApi';

import styles from './fill-document.module.css';

interface FillDocumentSideProps {
  id: string,
  name: string,
  tattrFolders: ITemplateSideGroup[],
  tables: ITemplateTable[],
  carticles: ITemplateVariation[],

  sideData?: ISideEditData|null,
  onChangeSidesData: Dispatch<SetStateAction<ISidesListEditData | undefined>>,
  sendingData?: ISideSendingData|null,
  onChangeSendingData: Dispatch<SetStateAction<ISidesListSendingData | null>>,

  isShowSelect?: boolean,
  onSetChanged: () => void,
}

interface FormRef {
  reset: UseFormReset<{ [key: string]: string }>
  getValues: UseFormGetValues<{ [key: string]: string }>,
  setValue: UseFormSetValue<{ [key: string]: string }>,
  revalidate: UseFormTrigger<{ [key: string]: string }>,
}

const clearArticleValue = (carticles: ITemplateVariation[], articleValues: ISideEditData['articleValues'], vid: string) => {
  let next = [ ...articleValues ];
  const carticle = carticles.find(article => article.id === vid);

  const articleIndex = next.findIndex(article => article.id === vid);
  if (articleIndex !== -1) next[articleIndex] = update(next[articleIndex], { value: { $set: [] } });

  carticle?.variants.forEach(({ id }) => {
    const childCarticle = carticles.find(({ parents }) => !!parents?.includes(id));
    if (childCarticle) next = clearArticleValue(carticles, next, childCarticle.id);
  });
  return next;
};

export const clearSideDataByCarticleId = (sideData: ISideEditData, carticles: ITemplateVariation[], vid: string): ISideEditData => {
  const articleValues = clearArticleValue(carticles, sideData.articleValues, vid);

  const tablesInCarticle: string[] = [];
  const tattrsInCarticle: string[] = [];

  const carticle = carticles.find(article => article.id === vid);
  carticle?.variants.forEach(({ tables, tattrs }) => {
    tablesInCarticle.push(...tables);
    tattrsInCarticle.push(...tattrs);
  });

  const tattrValues = { ...sideData.tattrValues };
  tattrsInCarticle?.forEach(id => {
    if (tattrValues[id]) delete(tattrValues[id]);
  });

  const tableValues = sideData.tableValues?.filter(({ tableId }) => !tablesInCarticle.includes(tableId));

  return {
    ...sideData,
    articleValues,
    tattrValues,
    tableValues
  };
};

const setArticleValue = (prevValues: ISideEditData['articleValues'], vid: string, ids: string[]) => {
  const articleValues = [ ...prevValues ];
  const index = articleValues.findIndex(item => item.id === vid);
  if (index === -1) articleValues.push({ id: vid, value: ids });
  else articleValues[index] = { id: vid, value: ids };
  return articleValues;
};

const FillDocumentSide: FC<FillDocumentSideProps> = ({
  id,
  tattrFolders,
  tables,
  carticles,
  sideData,
  onChangeSidesData,
  sendingData,
  onChangeSendingData,
  isShowSelect,
  onSetChanged
}) => {
  const formRef = useRef<FormRef|null>(null);
  const [ isShowCounteragents, setShowCounteragents ] = useState<boolean>(false);

  const [ getCounteragent ] = useLazyGetCounteragentByIdQuery();

  const handleSelectFolders = (tattrs: string[]) => {
    onChangeSendingData(prev => {
      if (!prev) return prev;
      return ({ ...prev, [id]: { ...prev[id], tattrs } });
    });
  };

  const handleSelectTable = (tid: string, checked: boolean) => {
    onChangeSendingData(prev => {
      if (!prev) return prev;

      const tables = [ ...prev[id].tables ];
      if (checked) tables.push(tid);
      else {
        const index = tables.indexOf(tid);
        if (index !== -1) tables.splice(index, 1);
      }

      return ({ ...prev, [id]: { ...prev[id], tables } });
    });
  };

  const handleSelectVariation = (vid: string, checked: boolean) => {
    onChangeSendingData(prev => {
      if (!prev) return prev;

      const articles = [ ...prev[id].articles ];
      if (checked) {
        articles.push(vid);
        // TODO: сделать reset значения вариации и выделение переменных и таблиц внутри вариации

        onChangeSidesData(prev => {
          if (!prev) return prev;
          return {
            ...prev,
            [id]: clearSideDataByCarticleId(prev[id], carticles, vid)
          };
        });
      } else {
        const index = articles.indexOf(vid);
        if (index !== -1) articles.splice(index, 1);
      }

      return ({ ...prev, [id]: { ...prev[id], articles } });
    });
  };

  const handleSetVariations = (vid: string, ids: string[]) => {
    onChangeSidesData(prev => {
      if (!prev) return prev;

      let articleValues = setArticleValue(prev[id].articleValues, vid, ids);
      const sideData = clearSideDataByCarticleId(prev[id], carticles, vid);

      if (ids.length === 0 && carticles.length > 1) {
        const flatVariantsCurrentCarticle = carticles.find(({ id }) => id === vid)?.variants.map(({ id }) => id);
        carticles.forEach(({ id, parents }) => {
          const isCurrentVariantParent = !!parents.find(parentId => flatVariantsCurrentCarticle?.includes(parentId));
          if (isCurrentVariantParent) articleValues = setArticleValue(articleValues, id, []);
        });
      };

      return ({ ...prev, [id]: { ...sideData, articleValues } });
    });
  };

  const handleChangeValid = (valid: boolean) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], isValid: valid } }));
  };

  const handleSetCounteragent = async (cid: string) => {
    const { data } = await getCounteragent(cid);
    if (data) {
      setShowCounteragents(false);
      const nameArr = data && [data.attrValues[101], data.attrValues[102], data.attrValues[103]];
      onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], counteragentid: cid, counteragentname: data && (data.attrValues[304] || nameArr.join(' ')) } }));
      resetValues(data.attrValues);
      return data;
    } return null;
  };

  const handleSetUpdate = (checked: boolean) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], updateCounteragent: checked } }));
  };

  const handleChangeForm = (tattrValues: { [key: string]: string }) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], tattrValues } }));
    onSetChanged();
  };

  const handleErrorForm = (tattrErrors: { [key: string]: string }) => {
    onChangeSidesData(prev => prev && ({ ...prev, [id]: { ...prev[id], tattrErrors } }));
  };

  const handleSetProducts = (
    tableId: string,
    products: { [key: string]: string }[], total: { [key: string]: string },
    customProductsIds: string[]
  ) => {
    onChangeSidesData(prev => {
      if (!prev) return prev;
      const tableValues = [ ...prev[id].tableValues ];
      const index = tableValues?.findIndex(table => table.tableId === tableId);
      if (!tableValues || index === -1) tableValues.push({ tableId, products, total, customProductsIds });
      else tableValues[index] = { tableId, products, total, customProductsIds };
      return ({ ...prev, [id]: { ...prev[id], tableValues } });
    });
    onSetChanged();
  };

  const resetValues = (data: { [key: string]: string }) => {
    const faltTattrs = tattrFolders?.flatMap(({ tattrs }) => tattrs);

    if (!faltTattrs || !formRef.current) return;

    const values = formRef.current.getValues();
    const newValues: { [key: string]: string } = {};

    Object.keys(values).forEach(key => {
      if (data[key] && faltTattrs.find(({ id }) => id === key)) newValues[key] = data[key];
      else newValues[key] = '';
    });

    formRef.current?.reset(newValues);
    setTimeout(() => formRef.current?.revalidate(), 1000);
  };

  return (
    <div>
      {!!tattrFolders.length && (
        <FillFromCardButton
          selectedId={sideData?.counteragentid}
          onClick={() => setShowCounteragents(!isShowCounteragents)}
          onCheck={checked => handleSetUpdate(checked)}
          selectedName={sideData?.counteragentname}
        />
      )}
      {isShowCounteragents && (
        <div className={styles.counteragents}>
          <ConteragentsSearch
            // queryHook={useGetCounteragentsQuery}
            onClickItem={(counteragent) => handleSetCounteragent(counteragent.id)}
          />
        </div>
      )}

      {!!(sideData && carticles?.length) && (
        <div className={styles.variations}>
          {sideData && carticles?.map((item) => (
            <FillVariations
              {...item}
              value={sideData.articleValues?.find(({ id }) => id === item.id)?.value ?? []}
              onChange={(ids) => handleSetVariations(item.id, ids)}
              selected={sendingData ? sendingData.articles.includes(item.id) : false}
              onSelect={checked => handleSelectVariation(item.id, checked)}
              showSelect={isShowSelect}
              key={item.id}
            />
          ))}
        </div>
      )}
      <div className={styles.fillingFields}>
        <div style={{ display: isShowCounteragents ? 'none' : 'block' }}>
          <FillForm
            values={sideData ? sideData.tattrValues : {}}
            folders={tattrFolders}
            onChange={values => handleChangeForm(values)}
            onErrors={errors => handleErrorForm(errors)}
            onChangeValid={valid => handleChangeValid(valid)}
            showSelect={isShowSelect}
            selected={sendingData?.tattrs}
            onChangeSelected={tattrs => handleSelectFolders(tattrs)}
            showGroupName
            showCount
            ref={formRef}
          />
        </div>
        {!!tables?.length && tables.map((table) => {
          const tableValues = sideData?.tableValues.find(({ tableId }) => tableId === table.id);
          return (
            <FillSideTable
              data={table}
              products={tableValues?.products ?? []}
              productsTotal={tableValues?.total ?? {}}
              customProductsIds={tableValues?.customProductsIds ?? []}
              onChangeTable={(products, total, customProductsIds) => {
                handleSetProducts(table.id, products, total, customProductsIds);
              }}
              selected={sendingData ? sendingData.tables.includes(table.id) : false}
              onSelect={checked => handleSelectTable(table.id, checked)}
              showSelect={isShowSelect}
              key={table.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FillDocumentSide;
