import { FC, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import useTimer from 'hooks/useTimer';
import classNames from 'classnames';

import styles from './request-progress.module.css';

const getTime = (sec_num: number) => {
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  const seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
};

interface RequestProgressProps {
  readyDate: Date,
}

const steps = [
  { lang: 'guide.request_steps.1' },
  { lang: 'guide.request_steps.2' },
  { lang: 'guide.request_steps.3' },
  { lang: 'guide.request_steps.4' },
  { lang: 'guide.request_steps.5', langEnd: 'guide.request_steps.6' }
];
const max = 3*60*60;
const stepDuration = max/steps.length;

const RequestProgress: FC<RequestProgressProps> = ({ readyDate }) => {
  const { t } = useTranslation();
  const secondsEnd = useMemo(() => Math.max(0, (readyDate.getTime() - new Date().getTime())/1000), [ readyDate ]);
  const { left } = useTimer(secondsEnd);

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('guide.request_progress_title')}</div>
      <div className={styles.status}>
        <div className={styles.progress}>
          <div className={styles.bar} style={{ width: Math.floor((max-left)*100/max)*1.2 + '%' }}/>
        </div>
        <div className={styles.time}>
          {getTime(left > 0 ? left : 0)}
        </div>
      </div>
      <div className={styles.description}>
        <ul className={styles.steps}>
          {steps.map(({ lang, langEnd }, index) => {
            const stepStart = index * stepDuration;
            const stepEnd = (index+1) * stepDuration;

            const isDone = left <= max-stepEnd;
            const isCurrent = left > max-stepEnd && left <= max-stepStart;
            const isLast = index === steps.length-1;

            return (
              <li className={classNames(styles.step, (isCurrent || (isDone && isLast)) && styles.active)} key={index}>
                {(isDone && !isLast) ? (
                  <ReactSVG src="/icons/guide_checked.svg" className={styles.stepIcon} />
                ) : (
                  <ReactSVG src="/icons/guide_dot.svg" className={styles.stepIcon} />
                )}
                {(isDone && isLast && langEnd)? t(langEnd) : t(lang)}
              </li>
            );
          })}
        </ul>
        <img className={styles.image} src="/images/documents-transfer.svg" alt="" />
      </div>
    </div>
  );
};

export default RequestProgress;
