import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button, LoadingButton, Input, ButtonsContainer } from '@forma/forma-ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import Timer from 'components/Timer';
import PageTitle from 'components/PageTitle';
import { MainLayout } from 'components/Layouts';
import PasswordCheck from 'views/profile/PasswordCheck';
import PasswordBanner from 'views/account/PasswordBanner';
import getValidationSchema from 'data/validationSchema';

import { useRecoverUserPasswordMutation } from 'store/auth/authApi';

import styles from './Account.module.css';

interface FormValues {
  password: string,
  confirm_password: string
}

const RecoverPassword = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const [ isShowPass, setShowPass ] = useState(false);
  const [ recoverUserPassword, { isLoading, isSuccess } ] = useRecoverUserPasswordMutation();

  const { register, handleSubmit, control, watch, formState: { errors, isValid } } = useForm<FormValues>({
    mode: 'onChange', resolver: yupResolver(getValidationSchema([ 'password', 'confirm_password' ]))
  });

  const password = watch('password');

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (!id) return null;
    const key = searchParams.get('key');
    if (!key) return null;

    recoverUserPassword({
      id: id,
      key: key,
      password: data.password
    });
  };

  const handleClickPasswordEye = (e: MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setShowPass(!isShowPass);
  };

  return (
    <MainLayout
      title={isSuccess ? t('account.recover_success_title') : t('changing_password')}
      subtitle={isSuccess ? t('account.recover_success_subtitle') : t('account.set_new_passsword')}
      sideChildren={<PasswordBanner visible={isShowPass} /> }
    >
      <PageTitle>{t('site_name') + ' – ' + t('changing_password')}</PageTitle>
      {isSuccess ? (
        <>
          <div className={styles.success}>
            <div className={styles.timer}>
              <Timer seconds={10} callback={() => navigate('/login')} />
            </div>
          </div>
          <ButtonsContainer className={styles.buttonsContainer}>
            <Button as={Link} to="/login" fullWidth={true} shadow={true}>{t('account.go_to_login')}</Button>
          </ButtonsContainer>
        </>
      ) : (
        <>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputs}>
              <Controller
                control={control}
                name="password"
                render={({ field: { value = '', ...rest } }) => (
                  <Input
                    id="password"
                    type={isShowPass ? 'text' : 'password'}
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    autoComplete="current-password"
                    {...rest}
                    {...register}
                    value={value}
                    meta={{
                      label: t('account.input_new_password'),
                      buttons: (
                        <img
                          src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'}
                          alt=""
                          onMouseDown={handleClickPasswordEye}
                        />
                      ),
                      error: !!errors?.password?.message,
                      errorMessage: errors?.password?.message && t('errors.' + errors.password.message),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="confirm_password"
                render={({ field: { value = '', ...rest } }) => (
                  <Input
                    id="confirm_password"
                    type={isShowPass ? 'text' : 'password'}
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    autoComplete="new-password"
                    {...rest}
                    {...register}
                    value={value}
                    meta={{
                      label: t('repeat_password'),
                      buttons: (
                        <img
                          src={isShowPass ? '/icons/eye-closed.svg' : '/icons/eye.svg'}
                          alt=""
                          onMouseDown={handleClickPasswordEye}
                        />
                      ),
                      error: !!errors?.confirm_password?.message,
                      errorMessage: errors?.confirm_password?.message && t('errors.' + errors.confirm_password.message),
                    }}
                  />
                )}
              />
              <div className={styles.passwordCheck}>
                <PasswordCheck password={password} />
              </div>
            </div>
            <ButtonsContainer className={styles.buttonsContainer}>
              <LoadingButton type="submit" disabled={!isValid} isLoading={isLoading} fullWidth shadow>{t('save_password')}</LoadingButton>
            </ButtonsContainer>
          </form>
        </>
      )}
    </MainLayout>
  );
};

export default RecoverPassword;
