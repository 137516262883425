import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@forma/forma-ui-kit';
import TariffItemSkelet from './TariffItemSkelet';
import TariffsListItem from './TariffsListItem';

import { IPromocode, ITariffItem } from 'interfaces/tariffs.interface';

import styles from './tariffs-list.module.css';

interface TariffsListProps {
  tariffs?: ITariffItem[],
  selectedDuration: number,
  hasPromoTariff: boolean,
  isShowPromo: boolean,
  onTogglePromo: (show: boolean) => void,
  promocode?: IPromocode|null,
  onSelectTariff: (data: { id: string, name: string, members: number, attachmentsSize: number }) => void
}

const TariffsList: FC<TariffsListProps> = ({
  tariffs, selectedDuration, hasPromoTariff, isShowPromo, onTogglePromo, promocode, onSelectTariff
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      {tariffs ? (
        tariffs.map(tariff => (
          <TariffsListItem
            tariff={tariff}
            selectedDuration={selectedDuration}
            promocode={promocode}
            onSelectTariff={onSelectTariff}
            key={tariff.id}
          />
        ))
      ) : (
        [...Array(3)].map((item, index) => (
          <TariffItemSkelet showAdditional={index === 2} key={index} />
        ))
      )}
      {hasPromoTariff && (
        <div>
          {isShowPromo ? (
            <Button viewStyle="textLight" onClick={() => onTogglePromo(false)}>{t('show_all')}</Button>
          ) : (
            <Button viewStyle="textLight" onClick={() => onTogglePromo(true)}>{t('subscription.show_promo_tariff')}</Button>
          )}
        </div>
      )}
    </div>
  );
};

export default TariffsList;