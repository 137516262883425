import { FC } from 'react';
import { t } from 'i18next';
import { Modal } from '@forma/forma-ui-kit';
import { format } from 'date-fns';

import { useGetCurrentTariffQuery } from 'store/tariffs/tariffsApi';
import { useRemoveAcquiringSubscriptionMutation } from 'store/billing/billingApi';

import styles from './CancelSubscriptionModal.module.css';

interface CancelSubscriptionModalProps {
  open?: boolean,
  onClose: (open: boolean) => void,
}

const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({ open, onClose }) => {
  const { data: tariff } = useGetCurrentTariffQuery();
  const [ cancelSubscription ] = useRemoveAcquiringSubscriptionMutation();

  const handleCancelSubscription = async () => {
    const result = await cancelSubscription();
    if ('data' in result) return true;
    return false;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100%"
      maxWidth="448px"
      title={t('subscription.cancel_subscription')}
      buttons={[{
        viewStyle: 'tertiary',
        onClick: () => onClose(false),
        children: t('subscription.not_cancel'),
        className: styles.button
      }, {
        viewStyle: 'danger',
        onClick: handleCancelSubscription,
        children: t('subscription.yes_cancel_subscription'),
        className: styles.button
      }]}
    >
      <div className={styles.description}>
        {tariff && t('subscription.cancel_subscription_description', { tariff: tariff.name, date: format(new Date(tariff?.dtTo), 'dd.MM.yyyy') })}
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
