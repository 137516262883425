import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer, Quantity } from '@forma/forma-ui-kit';
import formatPrice from 'helpers/formatPrice';
import { currencies } from 'data/mock';
import PaymentModal from '../PaymentModal';

import { ISignatureRequest, ISignatureTariffItem } from 'interfaces/tariffs.interface';
import { useGetSignaturePricesQuery } from 'store/tariffs/tariffsApi';

import styles from './signature.module.css';

interface SignatureProps {
  isAllowed?: boolean,
  tariffs?: ISignatureTariffItem[],
  current?: ISignatureRequest,
  actions: {
    onSetSignature: (params: { limit: number }) => Promise<string|null>,
    isSetSignatureLoading?: boolean,
    onCreateInvoice: (paymentId: string, billingData: Record<string, string>, source: 'signatureRequest') => Promise<boolean>,
    isCreateInvoiceLoading?: boolean,
    onDownloadInvoice: () => void
  }
}

const Signature = ({ isAllowed, tariffs, current, actions }: SignatureProps) => {
  const { t } = useTranslation();
  const currency = currencies.ru;

  const [ count, setCount ] = useState<number>(0);
  const [ isOpenPayment, setOpenPayment ] = useState<boolean>(false);

  const { data: prices, isLoading: isLoadingPrices } = useGetSignaturePricesQuery({ limit: count }, { skip: !count || !isOpenPayment });

  const handleSetPayment = () => (
    actions.onSetSignature({ limit: count })
  );

  const handleCreateInvoice = (paymentId: string, billingData: Record<string, string>) => (
    actions.onCreateInvoice(paymentId, billingData, 'signatureRequest')
  );

  if (!tariffs) return null;

  const baseTariff = tariffs.find(({ lowerBoundary }) => lowerBoundary === 0) ?? tariffs[0];
  const currentTariff = tariffs.reduce((acc, tariff) => (count > tariff.lowerBoundary ? tariff : acc), baseTariff);

  const baseTotalPrice = baseTariff.priceForOne * count;
  const totalPrice = currentTariff.priceForOne * count;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.title}>{t('subscription.sending_docs_on_signature')}</div>
        <div className={styles.description}>{t('subscription.sending_docs_on_signature_description')}</div>

        <div className={styles.row}>
          <div className={styles.qty}>
            <div className={styles.qtyInfo}>
              {t('subscription.signature_one_price', { price: formatPrice(currentTariff.priceForOne, currency) })}
            </div>
            <Quantity value={count} onChange={setCount} max={1000000} />
          </div>
          <div className={styles.discounts}>
            {tariffs.map(({ discount, lowerBoundary }) => {
              if (discount === 0) return null;
              return (
                <div className={styles.discountsItem} key={lowerBoundary}>
                  <div className={styles.discountsItemLabel}>
                    {`> ${lowerBoundary}`}
                  </div>
                  <div className={styles.discountsItemValue}>
                    {`-${discount}%`}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <ButtonsContainer className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={() => setOpenPayment(true)}
            disabled={count === 0 || !isAllowed}
          >
            {t('subscription.place_order')}
          </Button>
          <div className={styles.price}>
            <span className={styles.priceCurrent}>{formatPrice(totalPrice, currency, 2)}</span>
            {!!currentTariff?.discount && <span className={styles.discount}>-{currentTariff.discount}%</span>}
            {!!currentTariff?.discount && <span className={styles.priceOld}>{formatPrice(baseTotalPrice, currency)}</span>}
          </div>
        </ButtonsContainer>
      </div>
      <img className={styles.image} src="/images/signature.svg" alt="" width={481} height={274} />

      <PaymentModal
        open={isOpenPayment}
        onClose={setOpenPayment}
        defaultMethod="invoice"
        data={{
          name: t('subscription.sending_docs_on_signature'),
          source: 'signatureRequest',
          duration: current?.limit ?? 0,
          amountToPay: current?.price ?? prices?.price ?? 0,
          canChoose: true,
        }}
        actions={{
          onSetPayment: handleSetPayment,
          isSetPaymentLoading: actions.isSetSignatureLoading,
          onCreateInvoice: handleCreateInvoice,
          isCreateInvoiceLoading: actions.isCreateInvoiceLoading,
          onDownloadInvoice: actions.onDownloadInvoice
        }}
        isLoading={isLoadingPrices}
        disableCardPay
      />
    </div>
  );
};

export default Signature;
