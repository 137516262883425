import { FC } from 'react';
import { Grid } from 'components/ItemsGrid';
import StaticTemplatesGridItem, { StaticTemplatesGridItemSkelet } from './StaticTemplatesGridItem';

import { ISharedTemplatesItem } from 'interfaces/sharedTemplates.interface';

interface StaticTemplatesGridProps {
  isLoading?: boolean,
  searchValue?: string,
  items?: ISharedTemplatesItem[],
  onClickAdd: (id: string) => void,
  onClickUse: (id: string) => void
  addedTemplates: { id: string, translatedName: string }[],
}

const StaticTemplatesGrid: FC<StaticTemplatesGridProps> = ({
  isLoading, searchValue, items, onClickAdd, onClickUse, addedTemplates
}) => {
  return (
    <Grid columns={3}>
      {isLoading ? (
        [...Array(12)].map((_, index) => (
          <StaticTemplatesGridItemSkelet key={index} />
        ))
      ) : (
        items?.map(item => (
          <StaticTemplatesGridItem
            {...item}
            searchValue={searchValue}
            onClickAdd={onClickAdd}
            onClickUse={onClickUse}
            addedName={addedTemplates.find(({ id }) => id === item.id)?.translatedName}
            key={item.id}
          />
        ))
      )}
    </Grid>
  );
};

export default StaticTemplatesGrid;
