const vkId = process.env.REACT_APP_VK_ID;
const ymId = process.env.REACT_APP_YM_ID;

export const analytics = {
  createAccount: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'Start_a_free_periopd' });
  },
  goToAccountRegister: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'Go_to_the_account' });
  },
  checkVideoGide: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'whatch_video_guide' });
  },
  useDiscount: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'use_buttom_discount' });
  },
  dowloadDocument: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    window._tmr?.push({ type: 'reachGoal', id: vkId, goal: 'download_document' });
  },
  // successRegistration: () => {
  //   if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
  //   if (ym) ym(Number(ymId), 'reachGoal', 'Registration');
  // },
  successCreateCompany: () => {
    if (process.env.REACT_APP_ENABLE_ANALYTICS !== 'true') return;
    if (ym) ym(Number(ymId), 'reachGoal', 'Registration');
  },
};