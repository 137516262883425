import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@forma/forma-ui-kit';

import { IPromocode, ITariffDuration } from 'interfaces/tariffs.interface';

import styles from './tariff-durations.module.css';

interface TariffDurationsProps {
  patterns?: ITariffDuration[],
  selected: number,
  onSelect: (duration: number) => void,
  promocode?: IPromocode|null
}

const TariffDurations = ({ patterns, selected, onSelect, promocode }: TariffDurationsProps) => {
  const { t } = useTranslation();

  if (!patterns) return null;

  const haveUnusedPromo = promocode?.id && !promocode.used;

  return (
    <div className={styles.root}>
      {patterns.map(({ duration, discount }) => (
        <Button
          viewStyle={selected === duration ? 'primary' : 'secondary'}
          className={classNames(
            styles.button,
            selected === duration && styles.active,
            // (['view', 'await'].includes(mode) && selectedDuration !== duration) && styles.disabled
          )}
          onClick={() => onSelect(duration)}
          key={duration}
        >
          {duration && (
            <span className={styles.durationText}>
              {t('count_months', { count: duration / 30 })}
            </span>
          )}
          {(!!discount && !haveUnusedPromo) && (
            <span className={styles.durationDiscount}>
              {' '}-{discount}%
            </span>
          )}
        </Button>
      ))}
    </div>
  );
};

export default TariffDurations;
