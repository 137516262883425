import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonsContainer, LoadingButton, Modal, Tooltip, Input } from '@forma/forma-ui-kit';
import getValidationSchema from 'data/validationSchema';

import styles from './confirm-email.module.css';

import { useChangeProfileEmailMutation, useChangeEasyEmailMutation } from 'store/user/userApi';

interface ConfirmEmailProps {
  email: string,
  isEasy?: boolean
}

const ConfirmEmail: FC<ConfirmEmailProps> = ({ email, isEasy }) => {
  const { t } = useTranslation();
  const [ isOpenChange, setOpenChange ] = useState<boolean>(false);
  const [ changeEmailTo, setChangeEmailTo ] = useState<string>('');

  const [ changeEmail, { isLoading, isSuccess } ] = useChangeProfileEmailMutation();
  const [ changeEasyEmail, { isLoading: isLoadingEasy, isSuccess: isSuccessEasy } ] = useChangeEasyEmailMutation();

  const { register, control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(getValidationSchema(['login']))
  });

  const onSubmit = (data: { [key: string]: string }) => {
    if (isEasy) changeEasyEmail(data);
    else changeEmail(data);

    setChangeEmailTo(data.login);
  };

  return (
    <div className="success">
      <Trans
        i18nKey="confirm_email_notification"
        values={{
          tooltip: t('change_email')
        }}
        components={{
          email_tag:
            <Tooltip control={<span className={styles.link} onClick={() => setOpenChange(true)}>{email}</span>}>
              {t('change_email')}
            </Tooltip>
        }}
      />
      <Modal
        size="medium"
        open={isOpenChange}
        onClose={() => setOpenChange(false)}
        title={<div><Trans i18nKey="enter_actual_email_for_confirm" /></div>}
      >
        {(isSuccess || isSuccessEasy) ? (
          <div className={styles.modalContent}>
            <div className={styles.modalDescription}>
              <div>
                <Trans
                  i18nKey="we_send_to_email"
                  values={{ email: changeEmailTo }}
                  components={{ span: <span className="accent-text" /> }}
                />
              </div>
              <br />
              {t('go_to_link_from_email')}
            </div>

            <ButtonsContainer className={styles.modalButtons}>
              <LoadingButton
                type="submit"
                className={styles.modalButton}
                onClick={() => setOpenChange(false)}
              >
                {t('ok')}
              </LoadingButton>
            </ButtonsContainer>
          </div>
        ) : (
          <form className={styles.modalContent} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.modalDescription}>
              {t('enter_actual_email_description')}
            </div>
            <Controller
              control={control}
              name="login"
              render={({ field: { value = '', ...rest } }) => (
                <Input
                  placeholder={t('enter_new_email')}
                  maxLength={255}
                  inputMode="email"
                  {...rest}
                  {...register}
                  autoComplete="email"
                  value={value}
                  meta={{
                    containerClass: styles.field,
                    error: !!errors?.login?.message,
                    errorMessage: errors?.login?.message && t('errors.' + errors.login.message),
                    showClearButton: true
                  }}
                />
              )}
            />
            <ButtonsContainer className={styles.modalButtons}>
              <LoadingButton
                type="submit"
                className={styles.modalButton}
                isLoading={isLoading || isLoadingEasy}
              >
                {t('send')}
              </LoadingButton>
            </ButtonsContainer>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default ConfirmEmail;
