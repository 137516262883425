import { Navigate } from 'react-router-dom';
import * as Account from 'pages/Account';
import * as Common from 'pages/Common';
import * as Templates from 'pages/Templates';
import * as Editor from 'pages/Editor';
import * as Contragents from 'pages/Contragents';
import * as TemplatesPacks from 'pages/TemplatesPacks';
import * as Settings from 'pages/Settings';
import * as Safe from 'pages/Safe';
import * as MyDocuments from 'pages/MyDocuments';
import * as Products from 'pages/Products';
import * as StaticTemplates from 'pages/StaticTemplates';
import * as Tariffs from 'pages/Tariffs';
import LanguageRedirect from 'pages/LanguageRedirect';

const routes = [
  { path: '/', element: <Navigate to="/templates" replace={true} />, exact: true, logged: true },
  { path: '/ru', element: <LanguageRedirect />, exact: true, skip_auth: true },
  { path: '/en', element: <LanguageRedirect />, exact: true, skip_auth: true },
  { path: '/ge', element: <LanguageRedirect />, exact: true, skip_auth: true },

  { path: '/guide', element: <Common.Guide />, logged: true },

  { path: '/templates', element: <Templates.Folders />, logged: true },
  { path: '/templates/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:g/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:g/:h/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:g/:h/:i/:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:g/:h/:i/:g:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:g/:h/:i/:g/:k:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:g/:h/:i/:g/:k/:l:slug', element: <Templates.Folder />, logged: true },
  { path: '/templates/:a/:b/:c/:d/:e/:f/:g/:h/:i/:f/:g/:h/:i/:g/:k/:l/:m:slug', element: <Templates.Folder />, logged: true },
  { path: '/favourites', element: <Templates.Favourites />, logged: true },
  { path: '/fill-document/:slug', element: <Templates.Filling />, logged: true },

  { path: '/static-templates', element: <StaticTemplates.Templates />, logged: true },
  { path: '/static-templates/special', element: <StaticTemplates.SpecialTemplates />, logged: true },
  { path: '/static-templates/categories/:id', element: <StaticTemplates.TemplatesByCategory />, logged: true },
  { path: '/static-templates/:id', element: <StaticTemplates.Templates />, logged: true },

  { path: '/editor/:slug', element: <Editor.Editor />, logged: true },

  { path: '/contragents', element: <Contragents.Contragents />, logged: true },
  { path: '/contragents/:id', element: <Contragents.Contragent />, logged: true },
  { path: '/contragents/save-table', element: <Contragents.SaveContragents />, logged: true },
  { path: '/create-contragent/:type', element: <Contragents.ContragentCreate />, logged: true },

  { path: '/documents', element: <MyDocuments.Documents />, logged: true },
  // { path: '/completed-docs', element: <MyDocuments.MyDocuments />, logged: true },

  { path: '/profile', element: <Settings.Profile />, logged: true },
  { path: '/users', element: <Settings.UsersList />, logged: true },
  { path: '/user-groups', element: <Settings.UserGroups />, logged: true },
  { path: '/users/:id', element: <Settings.UserProfile />, logged: true },
  { path: '/invite', element: <Settings.UserInvite />, logged: true },
  { path: '/invite-success', element: <Settings.InviteSuccess />, logged: true },
  { path: '/confirm-email/:key', element: <Settings.ConfirmEmail />, logged: true },
  { path: '/invite/:id', element: <Account.SubmitInvite />, logged: false },

  { path: '/templates-packs', element: <TemplatesPacks.Packs />, logged: true, redirect: '/' },
  { path: '/templates-packs/:id', element: <TemplatesPacks.PackTemplates />, logged: true, redirect: '/' },
  { path: '/templates-pack-create', element: <TemplatesPacks.PackTemplates />, logged: true, redirect: '/' },
  { path: '/templates-pack-sides', element: <TemplatesPacks.PackSides />, logged: true, redirect: '/' },
  { path: '/templates-pack-sides/:id', element: <TemplatesPacks.PackSides />, logged: true, redirect: '/' },
  { path: '/fill-templates-pack/:id', element: <TemplatesPacks.PackFilling />, logged: true, redirect: '/' },

  { path: '/safe', element: <Safe.SafeDocuments />, logged: true, redirect: '/' },
  { path: '/safe/:id', element: <Safe.SafeDocuments />, logged: true, redirect: '/' },

  { path: '/auth', element: <Account.Auth />, skip_auth: true },
  { path: '/login', element: <Account.Login />, logged: false, redirect: '/' },
  { path: '/register', element: <Account.Register />, logged: false, redirect: '/' },
  { path: '/register/code', element: <Account.RegisterCode />, logged: false, redirect: '/' },
  { path: '/register/password', element: <Account.RegisterPassword />, logged: true, redirect: '/' },
  { path: '/register-user', element: <Account.RegisterUser />, logged: false, redirect: '/' },
  // { path: '/register-success', element: <Account.RegisterSuccess />, skip_auth: true },
  // { path: '/simple-register', element: <Account.SimpleRegister />, logged: false, redirect: '/' },
  { path: '/recover/:id', element: <Account.RecoverPassword />, skip_auth: true },
  { path: '/recover-password', element: <Account.Recover />, logged: false, redirect: '/' },
  { path: '/approved', element: <Account.ApproveSuccess />, skip_auth: true },
  { path: '/confirm/:id', element: <Account.ConfirmEmail />, skip_auth: true },

  { path: '/select-tariff', element: <Tariffs.SelectTariff />, logged: true },
  { path: '/billings', element: <Tariffs.Billings />, logged: true },

  { path: '/products', element: <Products.Products />, logged: true },
  { path: '/products/:id', element: <Products.ProductsEdit />, logged: true },
  { path: '/products/import', element: <Products.ProductsImport />, logged: true },
  { path: '/product-create', element: <Products.ProductsEdit />, logged: true },

  // { path: '/external-fill', element: <ExternalFill.ExternalFill />, logged: true },
  { path: '/external-fill/:id/download', element: <MyDocuments.ExternalFillDownload/>, logged: true },

  { path: '*', element: <Common.NotFound />, skip_auth: true }
];

export default routes;
