import { IBillingInfo, IInvoiceItem, IInvoiceType, IPaymentCardItem, IPaymentData, IPaymentSubscription } from 'interfaces/billing.interface';
import { baseApi } from '../store';

export type TInvoiceSource = 'tariffInstance'|'externalInfillEntity'|'signatureRequest';

export interface ICreateInvoiceData {
  id: string,
  source: TInvoiceSource,
  details: {
    companyname: string,
    inn: string,
    kpp: string,
    ogrn?: string,
    address: string
  }
}

export const billingApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getInvoices: builder.query<IInvoiceItem[], void>({
      query: () => ({
        url: '/accounting/documents/',
        method: 'GET'
      }),
      providesTags: ['Billing']
    }),
    getInvoiceTypes: builder.query<IInvoiceType[], void>({
      query: () => ({
        url: '/accounting/documents/types',
        method: 'GET'
      })
    }),
    createInvoice: builder.mutation<string, ICreateInvoiceData>({
      query: data => ({
        url: '/accounting/documents/invoice',
        method: 'POST',
        body: data,
        responseHandler: async (response: Response) => {
          if (!response.ok) return response.text().then(text => JSON.parse(text));
          return response.blob().then(blob => URL.createObjectURL(blob));
        }
      }),
      invalidatesTags: ['Billing', 'BillingUserData'],
    }),
    getBillingInfo: builder.query<IBillingInfo, void|null>({
      query: () => ({
        url: '/accounting/details',
        method: 'GET'
      }),
      providesTags: ['BillingUserData'],
    }),

    getAcquiringData: builder.mutation<IPaymentData, { id: string, source: string }>({
      query: data => ({
        url: '/acquiring/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Cards']
    }),
    getAcquiringCards: builder.query<IPaymentCardItem[], void|null>({
      query: () => ({
        url: '/acquiring/cards',
        method: 'GET'
      }),
      providesTags: ['Cards']
    }),
    getAcquiringSubscription: builder.query<IPaymentSubscription, void>({
      query: () => ({
        url: '/acquiring/subscriptions/active',
        method: 'GET'
      }),
      providesTags: ['Subscription'],
    }),
    removeAcquiringSubscription: builder.mutation<{ id: string }, void>({
      query: () => ({
        url: '/acquiring/subscriptions/active',
        method: 'DELETE'
      }),
      invalidatesTags: ['Subscription']
    }),
  })
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceTypesQuery,
  useCreateInvoiceMutation,
  useGetBillingInfoQuery,
  useGetAcquiringDataMutation,
  useGetAcquiringCardsQuery,
  useGetAcquiringSubscriptionQuery,
  useRemoveAcquiringSubscriptionMutation
} = billingApi;
