import { baseApi } from '../store';
import { setToken } from '../auth/authSlice';
import { setOnboardingItems, setUserData, setUserEmail, setUserPermissions, setUserPhone } from './userSlice';
import { IActivityItem, IPhoneConfirm, IUser, IUserItem, IWorkspace } from 'interfaces/users.interface';

interface UpdateUserData {
  name?: string,
  login?: string,
  phone?: string
}

interface RegisterWorkspaceData {
  username: string,
  phone: string,
  companyname: string,
  inn: string,
  goals: string,
  okved: string
}

interface UpdateWorkspaceData extends Partial<Omit<IWorkspace, 'areaOfActivity'|'isAdmin'>> {
  areasOfActivityId: string
}

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfileData: builder.query<IUser, void>({
      query: () => ({
        url: '/user/',
        method: 'GET'
      }),
      providesTags: ['User'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserData(data));
        } catch (error) {}
      }
    }),
    setProfileData: builder.mutation<IUser, UpdateUserData>({
      query: data => ({
        url: '/user/',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['User'],
    }),
    setProfilePassword: builder.mutation<{ token: string }, { oldPassword?: string, newPassword: string }>({
      query: data => ({
        url: '/user/password',
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
        } catch (error) {}
      }
    }),
    changeEasyEmail: builder.mutation({
      query: data => ({
        url: '/user/easylogin',
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setToken(data.token));
          dispatch(setUserEmail(args.login));
        } catch (error) {}
      },
      // invalidatesTags: ['User'],
    }),
    changeProfileEmail: builder.mutation({
      query: data => ({
        url: '/user/login',
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) dispatch(setUserEmail(args.login));
        } catch (error) {}
      },
    }),
    confirmProfileEmail: builder.query({
      query: data => ({
        url: `/user/changelogin`,
        method: 'GET',
        params: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserData(data));
        } catch (error) {}
      }
      // invalidatesTags: ['User'],
    }),
    getProfilePermissions: builder.query<string[], void>({
      query: () => ({
        url: `/user/permissions`,
        method: 'GET'
      }),
      transformResponse: (result: { permissions: string[] }) => result.permissions,
      providesTags: ['UserPermissions'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUserPermissions(data));
        } catch (error) {}
      }
    }),
    setProfilePhone: builder.mutation<IPhoneConfirm, { phone: string }>({
      query: data => ({
        url: '/user/phone',
        method: 'PUT',
        body: data
      }),
    }),
    confirmProfilePhone: builder.mutation<IPhoneConfirm, { code: string }>({
      query: data => ({
        url: '/user/phone/verify',
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) dispatch(setUserPhone(data.phone));
        } catch (error) {}
      },
    }),
    getOnboarding: builder.query<{ [key: string]: boolean }, void>({
      query: () => ({
        url: '/user/onboardingstatus',
        method: 'GET'
      }),
      providesTags: ['Onboarding'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) dispatch(setOnboardingItems(data));
        } catch (error) {}
      }
    }),
    setOnboarding: builder.mutation<{ [key: string]: boolean }, { [key: string]: boolean }>({
      query: data => ({
        url: '/user/onboardingstatus',
        method: 'PUT',
        body: data
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) dispatch(setOnboardingItems(data));
        } catch (error) {}
      },
    }),

    getUsersList: builder.query<{ count: number, items: IUserItem[] }, { [key: string]: string|number }>({
      query: data => ({
        url: `/users/`,
        method: 'GET',
        params: data
      }),
      providesTags: ['Users'],
    }),
    getUserData: builder.query({
      query: id => ({
        url: `/users/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, data) => [{ type: 'User', id: data.id }],
    }),
    setUserData: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, data) => [{ type: 'User', id: data.id }],
    }),
    addUser: builder.mutation({
      query: data => ({
        url: `/users/`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: builder.mutation({
      query: id => ({
        url: `/users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, data) => ['Users', { type: 'User', id: data.id }],
    }),
    inviteUser: builder.mutation<{ id: string, key: string, link: string }, { method: 'mail', login: string, name: string, groupids: string[] }>({
      query: data => ({
        url: `/users/invites`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Users'],
    }),
    removeUser: builder.mutation<{ id: string }, string>({
      query: id => ({
        url: `/users/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Users'],
    }),
    getUserGroups: builder.query({
      query: id => ({
        url: `/users/${id}/groups`,
        method: 'GET'
      }),
      // providesTags: (result, error, id) => [{ type: 'UserGroup', id: id }],
    }),
    setUserGroups: builder.mutation<{ id: string }, { id: string, groupids: string[] }>({
      query: ({ id, ...data }) => ({
        url: `/users/${id}/groups`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Users'],
    }),

    getCompanyActivity: builder.query<IActivityItem, { name?: string, description?: string, okved?: string }|void>({
      query: data => ({
        url: '/areasOfActivity/',
        method: 'GET',
        params: data
      }),
    }),
    getActivitiesList: builder.query<IActivityItem[], void>({
      query: () => ({
        url: '/areasOfActivityPublic/all',
        method: 'GET'
      }),
    }),
    setRegisterWorkspaceData: builder.mutation<RegisterWorkspaceData, RegisterWorkspaceData>({
      query: data => ({
        url: '/workspace/register',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['User', 'Workspace'],
    }),
    getWorkspaceData: builder.query<IWorkspace, void>({
      query: data => ({
        url: '/workspace',
        method: 'GET'
      }),
      providesTags: ['Workspace'],
    }),
    setWorkspaceData: builder.mutation<IWorkspace, UpdateWorkspaceData>({
      query: data => ({
        url: '/workspace',
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Workspace'],
    }),
  }),
});

export const {
  useGetProfileDataQuery,
  useSetProfileDataMutation,
  useSetProfilePasswordMutation,
  useChangeEasyEmailMutation,
  useChangeProfileEmailMutation,
  useLazyConfirmProfileEmailQuery,
  useGetProfilePermissionsQuery,
  useGetUserGroupsQuery,
  useSetProfilePhoneMutation,
  useConfirmProfilePhoneMutation,
  useGetOnboardingQuery,
  useSetOnboardingMutation,

  useGetUsersListQuery,
  useGetUserDataQuery,
  useSetUserDataMutation,
  useAddUserMutation,
  useDeleteUserMutation,
  useInviteUserMutation,
  useRemoveUserMutation,
  useSetUserGroupsMutation,

  useGetCompanyActivityQuery,
  useGetActivitiesListQuery,
  useSetRegisterWorkspaceDataMutation,
  useSetWorkspaceDataMutation,
  useGetWorkspaceDataQuery,
} = userApi;
