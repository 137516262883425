import { FC, FormEventHandler, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Input, Textarea, Button, DateInput, ButtonsContainer, FieldContainer, Tags, LoadingButton } from '@forma/forma-ui-kit';
import ConteragentsSearch from 'views/contragents/ConteragentsSearch';
import SelectCategories from '../DocumentCategories';
import { categories_colors } from 'data/mock';

import { IAttachmenItem, IAttachmentCategoriesItem } from 'interfaces/attachments.interface';
import { ICounteragentsItem } from 'interfaces/counteragents.interface';

import styles from './document-edit.module.css';

interface DocumentEditProps {
  data?: IAttachmenItem,
  name?: string,
  categories: {
    items?: IAttachmentCategoriesItem[],
    onCreate: (data: { name: string, color: string }) => Promise<IAttachmentCategoriesItem|null>,
    isCreateLoading: boolean
  },
  isSaveLoading: boolean,
  onSave: (data: { [key: string]: string|string[] }) => void, // fixme: add type
  onCancel: () => void
}

const DocumentEdit: FC<DocumentEditProps> = ({
  data, name, categories, isSaveLoading, onSave, onCancel
}) => {
  const { t } = useTranslation();
  const [ mode, setMode ] = useState<'fields'|'contragent'|'categories'|'createCategory'>('fields');
  const [ values, setValues ] = useState({
    name: name ?? data?.name ?? '',
    dtFrom: data?.dtFrom ?? '',
    dtTo: data?.dtTo ?? '',
    counteragentid: data?.counteragent.id ?? '',
    description: data?.description ?? '',
    categories: data?.categories.map(({ id }) => id) ?? []
  });
  const [ counteragent, setCounteragent ] = useState<ICounteragentsItem|undefined>(data?.counteragent);

  const handleSubmitForm: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSave(values);
  };

  const handleCreateCategory = async (data: { name: string, color: string }) => {
    const result = await categories.onCreate(data);
    if (result) setMode('categories');
    return result;
  };

  const handleRemoveCategory = (id: string) => {
    setValues(prev => {
      const categories = [ ...prev.categories ];
      categories.splice(categories.findIndex(itemId => itemId === id ), 1);
      return { ...prev, categories };
    });
  };

  if (mode === 'contragent') return (
    <div>
      <ButtonsContainer className={styles.headButtons}>
        <Button
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          onClick={() => setMode('fields')}
        >
          {t('go_back')}
        </Button>
      </ButtonsContainer>
      <ConteragentsSearch
        onClickItem={(counteragent) => {
          setValues(prev => ({ ...prev, counteragentid: counteragent.id }));
          setCounteragent(counteragent);
          setMode('fields');
        }}
      />
    </div>
  );

  if (mode === 'categories' || mode === 'createCategory') return (
    <div>
      <ButtonsContainer className={styles.headButtons}>
        <Button
          viewStyle="tertiary"
          size="small"
          className={styles.button}
          onClick={() => setMode(prev => prev === 'createCategory' ? 'categories': 'fields')}
        >
          {t('go_back')}
        </Button>
      </ButtonsContainer>
      <SelectCategories
        items={categories.items}
        isCreateLoading={categories.isCreateLoading}
        onCreate={handleCreateCategory}
        selected={values.categories}
        onSubmit={categories => {
          setValues(prev => ({ ...prev, categories }));
          setMode('fields');
          // setCategoriesList()
        }}
        isCreate={mode === 'createCategory'}
        onClickCreate={() => setMode('createCategory')}
      />
    </div>
  );

  return (
    <form className={styles.form} onSubmit={handleSubmitForm}>
      <div className={styles.inputs}>
        <Input
          id="name"
          name="name"
          viewStyle="secondary"
          placeholder={t('enter_document_name')}
          maxLength={255}
          value={values.name}
          onChange={e => setValues(prev => ({ ...prev, name: e.target.value }))}
          data-testid="name"
          required
          meta={{
            label: t('document_name'),
            showClearButton: true
          }}
        />
        <div className={styles.dates}>
          <DateInput
            id="dtFrom"
            name="dtFrom"
            viewStyle="secondary"
            placeholder="01.09.2024"
            value={values.dtFrom}
            onChange={e => setValues(prev => ({ ...prev, dtFrom: e.target.value }))}
            data-testid="dtFrom"
            meta={{
              label: t('validity'),
              maxDate: values.dtTo,
              portalId: 'popup-root'
            }}
          />
          <span className={styles.datesDivider}>—</span>
          <DateInput
            id="dtTo"
            name="dtTo"
            viewStyle="secondary"
            placeholder="02.09.2027"
            value={values.dtTo}
            onChange={e => setValues(prev => ({ ...prev, dtTo: e.target.value }))}
            data-testid="dtTo"
            meta={{
              label: <>&nbsp;</>,
              minDate: values.dtFrom,
              portalId: 'popup-root'
            }}
          />
        </div>
        <Input
          id="counteragentid"
          name="counteragentid"
          viewStyle="secondary"
          placeholder={t('enter_inn_or_organization_name')}
          maxLength={255}
          value={counteragent?.name || counteragent?.fullName || ''}
          onFocus={() => setMode('contragent')}
          data-testid="counteragentid"
          required
          meta={{
            label: t('contragent'),
            showClearButton: true
          }}
        />
        <Textarea
          id="description"
          name="description"
          viewStyle="secondary"
          placeholder={t('enter_document_description')}
          maxLength={255}
          value={values.description}
          onChange={e => setValues(prev => ({ ...prev, description: e.target.value }))}
          data-testid="description"
          meta={{
            label: t('description'),
            showClearButton: true
          }}
        />
        <FieldContainer
          id="categories"
          label={t('categories')}
        >
          <div>
            {values.categories ? (
              <Tags
                className={styles.categories}
                onClickAdd={() => setMode('categories')}
                items={values.categories.map(id => {
                  const category = categories.items?.find(category => category.id === id);
                  const color = category?.color ?? '';
                  return {
                    name: <div className={styles.category}>
                      {category?.name}
                      <ReactSVG className={styles.categoryRemove} src="/icons/close.svg" onClick={() => handleRemoveCategory(id)} />
                    </div>,
                    color: categories_colors[color]?.color,
                    backgroundColor: categories_colors[color]?.background,
                    // onClick: () => handleClickCategory(id)
                  };
                })}
              />
            ) : (
              <Button
                viewStyle="tertiary"
                size="small"
                className={styles.button}
                icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
                onClick={() => setMode('categories')}
              >
                {t('add_category')}
              </Button>
            )}
          </div>
        </FieldContainer>
      </div>
      <ButtonsContainer className={styles.buttons}>
        <LoadingButton
          type="submit"
          viewStyle="primary"
          size="small"
          className={styles.button}
          disabled={values.name.length < 3 || !values.counteragentid}
          isLoading={isSaveLoading}
        >
          {t('save')}
        </LoadingButton>
        <Button
          onClick={onCancel}
          viewStyle="tertiary"
          size="small"
          className={styles.button}
        >
          {t('cancel')}
        </Button>
      </ButtonsContainer>
    </form>
  );
};

export default DocumentEdit;
